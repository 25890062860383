import React, { useEffect, useState } from "react";
import logo from "../logo.png";

const NavbarLogo = ({ onClick, link = false }) => {
  const [scrolledPastFirstScreen, setScrolledPastFirstScreen] = useState(false);
  const position = scrolledPastFirstScreen;

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY > window.innerHeight;
      setScrolledPastFirstScreen(scrolled);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const inner = (
    <div className="flex flex-col items-center cursor-pointer">
      <img
        alt="Pole Star Group Logo"
        src={logo}
        onClick={onClick}
        className={`${
          position === false ? "w-8 md:w-20" : "w-7 md:w-16"
        } ease-in-out mt-[2px] md:mt-16 hover:scale-105 transition-transform duration-700`}
      />
      <p
        className={`${
          position === false ? "text-base md:text-lg" : "text-sm md:text-base"
        }  font-semibold text-black`}
      >
        Pole Star Group
      </p>
    </div>
  );

  if (link) return <a href="/">{inner}</a>;
  return <div>{inner}</div>;
};

export default NavbarLogo;
