import React, { useState, useEffect, useRef } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import { hoverScale } from "./StyleStandardize";

const FloatingButton = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const buttonRef = useRef(null);

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  const handleClickOutside = (event) => {
    if (buttonRef.current && !buttonRef.current.contains(event.target)) {
      setIsExpanded(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const buttonStyle =
    "bg-[#17371b] text-white p-2 cursor-pointer flex flex-row gap-1 rounded-lg";

  const contacts = [
    {
      icon: <LinkedInIcon />,
      text: "LinkedIn",
      pathLink: "/",
    },
    {
      icon: <FacebookIcon />,
      text: "Facebook",
      pathLink: "/",
    },
    {
      icon: <InstagramIcon />,
      text: "Instagram",
      pathLink: "/",
    },
  ];

  return (
    <div className="fixed bottom-2 right-2 flex flex-col items-end z-50">
      {/* Circle button */}
      <div
        ref={buttonRef}
        className={`rounded-full bg-[#17371b] text-white p-2 text-xs cursor-pointer relative ${
          isExpanded === true ? "animate-spin" : "animate-bounce"
        } `}
        onClick={toggleExpansion}
      >
        <MenuIcon />
      </div>

      {/* Expanded buttons */}
      <div
        className={`${
          isExpanded ? "flex flex-col gap-2 absolute w-max" : "hidden"
        } rounded-md`}
        style={{
          transform: `translateY(${isExpanded ? "-105%" : "0"})`,
        }}
      >
        {contacts.map((el, i) => {
          return (
            <div
              className={`${buttonStyle} ${hoverScale}`}
              onClick={() => {
                window.location.href = el.pathLink;
              }}
            >
              {el.icon} {el.text}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FloatingButton;
