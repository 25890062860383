import React from "react";
import { useState, useEffect } from "react";
import ArrowRightIcon from "@mui/icons-material/ArrowForward";
import { cards } from "./CardElements";
import "./Home.css";

export default function WhyChooseUs() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [nextCardIndex, setNextCardIndex] = useState(1);
  const totalServices = cards.length;
  const slidesToShowLarge = 4;

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % totalServices);
  };

  const getVisibleCards = () => {
    const visibleCards = [];
    for (let i = 0; i < slidesToShowLarge; i++) {
      visibleCards.push(cards[(currentIndex + i) % totalServices]);
    }
    return visibleCards;
  };

  const handleDotClick = (dotIndex) => {
    setCurrentIndex(dotIndex);
  };

  const handleArrowClick = () => {
    setNextCardIndex((prevIndex) => (prevIndex + 1) % totalServices);
    nextSlide();
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      nextSlide();
    }, 7000);
    return () => {
      clearTimeout(timer);
    };
  }, [currentIndex]);
  return (
    <div className="bg-[#14361B] py-10">
      <div className="flex flex-col gap-10 justify-center items-start w-11/12 mx-auto">
        {/* <Slide right duration={1400}> */}

        <p className="text-white text-left text-4xl pb-7 border-b border-gray-200 mb-10 w-11/12 font-Playfair">
          Why Choose Us?
        </p>
        {/* </Slide> */}

        <div className="flex flex-row flex-wrap justify-center gap-3 text-white">
          {getVisibleCards().map((el, i) => {
            return (
              <div
                key={i}
                className={`w-11/12 md:w-[24%] h-full text-left border border-1 border-[#BBBBBB] hover:bg-[#ED2025] p-10 flex flex-col gap-4`}
              >
                <p className="bg-white py-3 px-4 w-max mr-auto text-black rounded-full">
                  {el.index}
                </p>
                <p className="text-2xl">{el.title}</p>
                <p>{el.description}</p>

                <button
                  onClick={handleArrowClick}
                  className="bg-[#ED2025] text-white border-2 border-[#14361B] rounded-full pt-[6px] pb-[10px] px-3 mr-auto"
                >
                  <ArrowRightIcon fontSize="inherit" />
                </button>
              </div>
            );
          })}
        </div>

        <DotNavigator
          currentIndex={currentIndex}
          totalServices={totalServices}
          onClickDot={handleDotClick}
        />
      </div>
    </div>
  );
}

const DotNavigator = ({ currentIndex, totalServices, onClickDot }) => {
  const dots = Array.from(
    { length: Math.ceil(totalServices / 4) },
    (_, index) => index
  );

  return (
    <div className="flex justify-center mt-3">
      {dots.map((dotIndex) => (
        <div
          key={dotIndex}
          className={`w-3 h-3 mx-1 border-2 border-white rounded-full cursor-pointer ${
            currentIndex >= dotIndex * 4 && currentIndex < (dotIndex + 1) * 4
              ? "bg-white"
              : ""
          }`}
          onClick={() => onClickDot(dotIndex * 4)}
        ></div>
      ))}
    </div>
  );
};
