import React, { useRef } from "react";
import "./FlagSlider.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { medialUrl } from "../../../EndPoints";

const logo1 = `${medialUrl}/images/flags/Mexico.png`;
const logo2 = `${medialUrl}/images/flags/Singapore.png`;
const logo3 = `${medialUrl}/images/flags/Spain.png`;
const logo4 = `${medialUrl}/images/flags/United_Kingdom.png`;
const logo5 = `${medialUrl}/images/flags/Ireland.png`;
const logo6 = `${medialUrl}/images/flags/Austria.png`;
const logo7 = `${medialUrl}/images/flags/Switzerland.png`;
const logo8 = `${medialUrl}/images/flags/Belgium.png`;
const logo9 = `${medialUrl}/images/flags/France.png`;
const logo10 = `${medialUrl}/images/flags/Germany.png`;

export default function LogoSlider() {
  const swiperRef = useRef();
  return (
    <div className="mb-5 mt-10 flex flex-col justify-center items-center content-center">
      <h1 className="text-[32px] font-Playfair pb-2">We Export Worldwide</h1>

      <Swiper
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
        }}
        spaceBetween={3}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
        slidesPerView={5} // Default slidesPerView
        loop
        className="w-full h-auto my-[40px]"
        breakpoints={{
          640: {
            slidesPerView: 6,
          },
          340: {
            slidesPerView: 2,
          },
        }}
      >
        <div className="flex flex-col gap-5 mx-auto justify-center items-center">
          {flagContent1.map((el, i) => {
            return (
              <SwiperSlide key={i} className="flex justify-center items-center">
                <div className="flag flex flex-col justify-center items-center">
                  <img src={el.src} alt={el.alt} className="shadow" />
                  <p className="text-center font-Poppins font-semibold">
                    {el.country}
                  </p>
                </div>
              </SwiperSlide>
            );
          })}
        </div>
      </Swiper>

      <Swiper
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
        }}
        spaceBetween={3}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
        slidesPerView={5} // Default slidesPerView
        loop
        className="w-full h-auto my-[40px]"
        breakpoints={{
          640: {
            slidesPerView: 6,
          },
          340: {
            slidesPerView: 2,
          },
        }}
      >
        <div className="flex flex-col gap-5 mx-auto justify-center items-center">
          {flagContent2.map((el, i) => {
            return (
              <SwiperSlide key={i} className="flex justify-center items-center">
                <div className="flag flex flex-col justify-center items-center">
                  <img src={el.src} alt={el.alt} className="shadow" />
                  <p className="text-center font-Poppins font-semibold">
                    {el.country}
                  </p>
                </div>
              </SwiperSlide>
            );
          })}
        </div>
      </Swiper>

      <div className="mt-10 flex flex-row flex-wrap gap-5 md:gap-32 mx-auto justify-center items-center">
        <div className="flag flex flex-col justify-center items-center">
          <img src={logo1} alt="Mexico" className="shadow" />
          <p className="text-center font-Poppins font-semibold">Mexico</p>
        </div>

        <div className="flag flex flex-col justify-center items-center">
          <img src={logo2} alt="Singapore" className="shadow" />
          <p className="text-center font-Poppins font-semibold">Singapore</p>
        </div>
      </div>
    </div>
  );
}

const flagContent1 = [
  { src: logo1, country: "Mexico" },
  { src: logo2, country: "Singapore" },
  { src: logo3, country: "Spain" },
  { src: logo4, country: "United Kingdom" },
  { src: logo5, country: "Ireland" },
  { src: logo6, country: "Austria" },
  { src: logo7, country: "Switzerland" },
  { src: logo8, country: "Belgium" },
];
const flagContent2 = [
  { src: logo6, country: "Austria" },
  { src: logo7, country: "Switzerland" },
  { src: logo8, country: "Belgium" },
  { src: logo9, country: "France" },
  { src: logo10, country: "Germany" },
  { src: logo3, country: "Spain" },
  { src: logo4, country: "United Kingdom" },
  { src: logo5, country: "Ireland" },
];
