import React from "react";
import {
  LineDecorator,
  RedTriangleFilledDecorator,
  RedTriangleNotFilledDecorator,
} from "../../Products/Woven/components/WovenMedias";
import {
  eastbalishmentImage,
  machinery1Image,
  machinery3Image,
} from "./ManufactureMedias";

const Manufacturing = () => {
  return (
    <>
      <div className="relative">
        <ManufacturingMainContent />
        {/* decorator */}
        <img src={LineDecorator} alt="icon" className="absolute -bottom-60" />
      </div>

      {/* decorator */}
      <div className="flex justify-between bottom-3 w-2/3 mx-auto">
        <img
          className="size-[100px]"
          alt="decorator"
          src={RedTriangleFilledDecorator}
        />
        <img
          className="size-[100px]"
          alt="decorator"
          src={RedTriangleNotFilledDecorator}
        />
      </div>
    </>
  );
};

export default Manufacturing;

const ManufacturingMainContent = () => {
  const establishments = [
    "Fire Station",
    "Hospital",
    "Police Station",
    "Railway Station",
    "Bus Stand",
  ];
  return (
    <div className="text-left flex flex-row flex-wrap justify-center gap-8 p-6">
      {/* First column */}
      <div className="flex flex-col gap-5">
        <div className="flex flex-col gap-3">
          <h2 className="text-[44px] md:text-5xl font-bold mb-4 uppercase text-[#435E49] font-Playfair">
            Manufacturing <br />& MOQ
          </h2>
          <p className="text-gray-700 w-80">
            Our dedicated teams ensure high quality and craftsmanship standards
            remain consistent whether order quantity is 1,000 or 100,000 pieces.
            <br />
            MOQ is 2,000 pieces per color, per style. A large order quantity
            allows higher efficiency at a reduced manufacturing cost.
          </p>
        </div>
        <div className="flex flex-col gap-3">
          <h2 className="text-4xl font-bold my-4 uppercase text-[#14361B]">
            Machinery in <br />
            Plant
          </h2>
          <img src={machinery1Image} alt="Machinery" className="w-80" />
        </div>
      </div>

      {/* Second Column */}
      <div className="flex flex-col gap-5 mb-auto">
        <img
          src={eastbalishmentImage}
          alt="Truck Icon"
          className="w-52 m-auto"
        />
        <h3 className="text-3xl font-bold uppercase text-[#14361B]">
          Important
          <br />
          Establishments
        </h3>
        <ul className="list-decimal pl-4">
          {establishments.map((el, i) => {
            return <li>{el}</li>;
          })}
        </ul>
      </div>

      {/* Third Column */}
      <div className="flex flex-col gap-5">
        <div className="h-[75px] bg-[#14361B]"></div>
        <div className="flex flex-col gap-3">
          <img
            src={machinery3Image}
            alt="Safety"
            className="w-80 h-96 rounded-sm"
          />
          <p className="text-2xl text-right uppercase text-[#14361B]">
            Safety is Mainatained
          </p>
        </div>
      </div>
    </div>
  );
};
