import React, { useState } from "react";
import ScrollTrigger from "react-scroll-trigger";
import CountUp from "react-countup";

const Counter = ({ stat }) => {
  const [counterOn, setCounterOn] = useState(false);

  return (
    <ScrollTrigger
      onEnter={() => setCounterOn(true)}
      onExit={() => setCounterOn(false)}
    >
      <div
        className="bg-white shadow border-2 border-solid w-max p-5 rounded-2xl flex flex-col md:flex-row flex-wrap gap-8 mx-auto -mt-12 
      absolute z-10 right-1/4"
      >
        {stat.map((el, i) => {
          return (
            <div
              className={`flex flex-row items-center justify-center gap-4 ${
                i !== 1 &&
                "border-b-2 border-b-red-100 py-[10px] md:border-b-0 md:border-r-2 md:border-r-red-100 md:pr-5"
              }`}
            >
              <div className="text-left">
                <p className="font-bold text-4xl border-b-4 border-[#f09c9d] w-max">
                  {counterOn && i !== 1 && el.number && (
                    <CountUp start={0} end={el.number} duration={2} delay={0} />
                  )}
                  {i === 1 && <span>{el.number}</span>}
                  <span>{el.sign}</span>
                </p>
                <p className="underline underline-offset-1 w-36">{el.text}</p>
              </div>
              <img src={el.icon} alt="" className="h-8" />
            </div>
          );
        })}
      </div>
    </ScrollTrigger>
  );
};

export default Counter;
