import { medialUrl } from "../../../EndPoints";

export const heroPoleStarFashionImage = `${medialUrl}/img/SisterConcern/HeroSisterConcern1.png`;
export const statPoleStarFashion = [
  {
    number: 10,
    text: "Lines",
    icon: `${medialUrl}/img/icons/Apartment.jpg`,
  },
];

export const productLineTitle = "Our Division";
export const productLinePoleStarFashionContent = [
  [
    {
      division: "Woven Division",
      title: "Product Line",
      text: "Men’s, Ladies & Children Wear (Basic Shirt & Classical Shirt) Tops & Bottom.",
    },
    {
      division: "Woven Division",
      title: "Using Yarn/ Fabrics type",
      text: "100% Cotton, 100% Cotton Y/D, 65 % polyesters 35 % Cotton, 100 % Cotton Y/D Twill Filament, Input Fabric Like Viscose etc.",
    },
  ],
  [
    {
      division: "Knit Division",
      title: "Product Line of Knit Item",
      text: "Ladies Long Sleeve T-Shirt, Ladies Tank Top, Ladies S/S T-Shirt, Ladies Frog, Polo Shirt, Bottom, Night Dress, Kids Item, Yoga, viscose etc.",
    },
    {
      division: "Knit Division",
      title: "Using Yarn/ Fabrics type",
      text: "100% Cotton, 100% Cotton Y/D, 65 % polyesters 35 % Cotton, 100 % Cotton Y/D Twill Filament, Input Fabric Like Viscose etc.",
    },
  ],
];

export const associateBusinessPoleStarFashion = {
  title: "Sister Concerns",
  outerImage: `${medialUrl}/img/SisterConcern/sister_concern.png`,
  arr: [
    {
      img: `${medialUrl}/img/SisterConcern/pole_star_printing.png`,
      innerTitle: "Pole Star Printing & Embroidery",
    },
    {
      img: `${medialUrl}/img/SisterConcern/pole_star_knitting.png`,
      innerTitle: "Pole Star Knitting Ltd.",
    },
  ],
};

export const OurCustomersPoleStarFashionImages = [
  [
    `${medialUrl}/brands/vertbaudet.png`,
    `${medialUrl}/brands/sfrea.png`,
    `${medialUrl}/brands/aldi.png`,
    `${medialUrl}/brands/la_halle.png`,
    `${medialUrl}/brands/regatta.png`,
    `${medialUrl}/brands/mango.png`,
    `${medialUrl}/brands/mew_look.png`,
  ],
  [
    `${medialUrl}/brands/ostin.png`,
    `${medialUrl}/brands/identity.png`,
    `${medialUrl}/brands/mango.png`,
    `${medialUrl}/brands/Artsana-removebg-preview.png`,
    `${medialUrl}/brands/mew_look.png`,
    `${medialUrl}/brands/gemo.png`,
  ],
  [
    `${medialUrl}/brands/sport_group_denmark.png`,
    `${medialUrl}/brands/Chicco.png`,
    `${medialUrl}/brands/aldi.png`,
    `${medialUrl}/brands/identity.png`,
    `${medialUrl}/brands/ostin.png`,
    `${medialUrl}/brands/vertbaudet.png`,
    `${medialUrl}/brands/regatta.png`,
  ],
];
