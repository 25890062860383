import { medialUrl } from "../../EndPoints";

export const heroManufactureImage = `${medialUrl}/img/manufacture/manu.jpeg`;

// Manufacturing
export const machinery1Image = `${medialUrl}/img/manufacture/machinery1.png`;
export const machinery3Image = `${medialUrl}/img/manufacture/machinery3.jpeg`;
export const eastbalishmentImage = `${medialUrl}/img/manufacture/establishment.png`;

// Machineries
const machine1Image = `${medialUrl}/img/manufacture/machine1.png`;
const machine2Image = `${medialUrl}/img/manufacture/machine2.png`;
const machine3Image = `${medialUrl}/img/manufacture/machine3.png`;

export const MachineriesImages = [
  {
    image: machine1Image,
    alt: "machine1",
  },
  { image: machine2Image, alt: "machine2" },
  {
    image: machine3Image,
    alt: "machine3",
  },
];

// FactoryBuilding
const PoleStarFashionDesignImage = `${medialUrl}/img/manufacture/factory1.jpeg`;
const PoleStarKnitImage = `${medialUrl}/img/manufacture/factory2.jpeg`;
const PrimeSweaterImage = `${medialUrl}/img/manufacture/factory3.jpg`;
const BDKnitImage = `${medialUrl}/img/manufacture/knit.png`;
export const FactoryBuildingContent = [
  {
    src: PoleStarFashionDesignImage,
    title: "Pole Star Fashion Design Ltd.",
  },
  {
    src: PoleStarKnitImage,
    title: "Pole Star Knit Composite Ltd.",
  },
  {
    src: PrimeSweaterImage,
    title: "Prime Sweater",
  },
  {
    src: BDKnitImage,
    title: "BD Knit Design Ltd.",
  },
];

// Companies - Manufacture
const atlas_copco = `${medialUrl}/img/manufacture/atlas_copco.png`;
const bry_air = `${medialUrl}/img/manufacture/bry_air.png`;
const brother_logo = `${medialUrl}/img/manufacture/brother_logo.png`;

const cintex = `${medialUrl}/img/manufacture/cintex.jpeg`;
const hasima = `${medialUrl}/img/manufacture/hasima.jpeg`;
const juki = `${medialUrl}/img/manufacture/juki.png`;
const kann = `${medialUrl}/img/manufacture/kann.jpeg`;
const kansai = `${medialUrl}/img/manufacture/kansai.jpeg`;
const schmidt = `${medialUrl}/img/manufacture/schmidt.jpeg`;
const schroders = `${medialUrl}/img/manufacture/schroders.png`;
const km = `${medialUrl}/img/manufacture/km.jpeg`;
const lectra = `${medialUrl}/img/manufacture/lectra.jpeg`;
const ngai = `${medialUrl}/img/manufacture/ngai.jpeg`;
const verivide = `${medialUrl}/img/manufacture/verivide.png`;
const zeal = "https://images.rebelsoftt.com/img/manufacture/zeal.png";

export const machineriesImages = [
  [juki, kansai, brother_logo, atlas_copco, schroders],
  [zeal, bry_air, cintex, hasima, kann],
  [km, ngai, lectra, verivide, schmidt],
];
