import React from "react";
import { motion } from "framer-motion";
import { Slide } from "react-reveal";
const Header = ({ src, title, title2, alt, text, strongText }) => {
  return (
    <>
      <motion.div
        initial={{ opacity: 0.5 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <img
          className="w-11/12 brightness-50 md:w-4/5 h-[200px] md:h-[300px] mx-auto mt-16 md:mt-28 text-white rounded-3xl"
          src={src}
          alt={alt}
        />
      </motion.div>
      <Slide left duration={2000}>
        <div className="text-left flex flex-col gap-5 md:gap-8 absolute top-[9%] md:top-[20%] left-[9%] md:left-[15%] text-white">
          <p className="md:w-3/5 text-3xl md:text-4xl font-[Kanit] font-bold">
            {title}
            <br />
            {title2 && title2}
          </p>
          <p className="font-[Lato]">
            {text}
            <strong>{strongText}</strong>
          </p>
        </div>
      </Slide>
    </>
  );
};

export default Header;
