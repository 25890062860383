import { medialUrl } from "../../../EndPoints";

export const ApartmentIcon = `${medialUrl}/img/icons/Apartment.jpg`;
export const LineDecorator = `${medialUrl}/img/QA/line.png`;
export const RedTriangleFilledDecorator = `${medialUrl}/img/QA/triangleFilled.png`;
export const RedTriangleNotFilledDecorator = `${medialUrl}/img/QA/triangle.png`;

// Woven
export const WovenHeroImage = `${medialUrl}/img/Woven/Woven_Hero.png`;

const ManLadiesChildrenImage = `${medialUrl}/img/Woven/Woven_Second_Section.png`;
const BoxerImage = `${medialUrl}/img/Woven/Boxer.png`;
const SportsWearImage = `${medialUrl}/img/Woven/sports.png`;
const LadiesTopImage = `${medialUrl}/img/Woven/ladies_top.png`;

export const wovenArray = [
  {
    img: ManLadiesChildrenImage,
    text: "Men’s, Ladies & Children Wear",
  },
  {
    img: BoxerImage,
    text: "Boxer",
  },
  {
    img: SportsWearImage,
    text: "Sports Wear",
  },
  {
    img: LadiesTopImage,
    text: "Ladies Top",
  },
];

export const wovenStatCounter = [
  {
    number: 8,
    text: "Lines",
    icon: ApartmentIcon,
  },
  {
    number: "0.0",
    sign: "%",
    text: "Re-work & Re-check",
    icon: ApartmentIcon,
  },
];

// WovenForthSection
export const woven_video = `${medialUrl}/videos/woven.mp4`;
const cloth1 = `${medialUrl}/images/cloth1.png`;
const cloth2 = `${medialUrl}/images/cloth2.png`;
export const WovenForthSectionContent = [
  {
    img: cloth1,
    alt: "Cloth Type 1",
    text: "From tailored classics to contemporary masterpieces, each piece is meticulously crafted to perfection",
  },
  {
    img: cloth2,
    alt: "Cloth Type 2",
    text: "Ensuring unparalleled quality and unmatched attention to detail. Explore our woven division and discover the epitome of sartorial excellence.",
  },
];
