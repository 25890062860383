import React from "react";
import MediaLinks from "../../components/MediaLinks";
import SisterConcernHeroLeftContent from "./SisterConcernHeroLeftContent";

const SisterConcernHero = ({ bgImage, title }) => {
  const backgroundStyle = {
    backgroundImage: `url(${bgImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    transition: "background-image 1s ease-in-out",
  };
  return (
    <div>
      <div className="relative">
        <div
          className="h-[450px] w-4/5 mt-16 md:mt-28 mx-auto text-white relative rounded-2xl"
          style={backgroundStyle}
        >
          <div className="w-4/5 absolute text-left top-[20%] md:top-[35%] left-2 md:left-5 ">
            <p className="text-xl flex flex-row flex-wrap gap-3 font-semibold">
              <div className="w-7 h-[2px] bg-white my-auto" />
              Sister Concern
            </p>
            <p className="text-4xl md:text-5xl font-semibold font-Playfair">
              {title}
            </p>
          </div>
          <SisterConcernHeroLeftContent />
        </div>
        <MediaLinks />
      </div>
    </div>
  );
};

export default SisterConcernHero;
