import React from "react";
import Counter from "../../components/Counter";
import MediaLinks from "../../components/MediaLinks";

const HeroSection = ({ bgImage, statCounter, title }) => {
  const backgroundStyle = {
    backgroundImage: `url(${bgImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    transition: "background-image 1s ease-in-out",
  };

  return (
    <>
      <div>
        <div
          className="h-[450px] w-4/5 mt-16 md:mt-28 mx-auto text-white relative rounded-2xl"
          style={backgroundStyle}
        >
          <p className="w-3/5 absolute text-left top-[20%] left-8 flex flex-row gap-3 text-4xl md:text-5xl font-semibold font-Playfair">
            {title}
          </p>
        </div>

        <Counter stat={statCounter} />
      </div>
      <MediaLinks />
    </>
  );
};
export default HeroSection;
