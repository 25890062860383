import React from "react";
import Achievements from "../../Home/components/Achievements";
import SecondBdKnitDesign from "./components/SecondBdKnitDesign";
import SisterConcernHero from "../components/SisterConcernHero";
import MainProducts from "../components/MainProducts";
import OurCustomers from "../components/OurCustomers";
import {
  associateBusinessBDKnit,
  heroBDKnitImage,
  OurCustomersBDKnitImages,
} from "./components/BDKnitMedia";
const BdKnitDesign = () => {
  return (
    <div className="flex flex-col gap-12">
      <SisterConcernHero
        bgImage={heroBDKnitImage}
        title="BD Knit Design Ltd."
      />
      <SecondBdKnitDesign />
      <Achievements />
      <OurCustomers title="Our Customers" imageSet={OurCustomersBDKnitImages} />
      <MainProducts content={associateBusinessBDKnit} />
    </div>
  );
};

export default BdKnitDesign;
