import React, { useRef } from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

function OurCustomers({ title, imageSet }) {
  const swiperRef = useRef();

  return (
    <div className="w-full mx-auto flex flex-col justify-center items-center bg-white py-5">
      {title && (
        <p className="font-bold mb-10 font-Playfair text-5xl md:text-[64px]">
          {title}
        </p>
      )}
      <div className="relative w-full h-auto flex flex-col pl-[3.5%]">
        {imageSet.map((el, i) => (
          <Swiper
            key={i}
            onSwiper={(swiper) => {
              swiperRef.current = swiper;
            }}
            spaceBetween={i % 2 === 0 ? 4 : 5}
            autoplay={{
              delay: 2500 + i * 200,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            slidesPerView={4} // Default slidesPerView
            loop
            className="w-full h-auto"
            breakpoints={{
              640: {
                slidesPerView: 4,
              },
              340: {
                slidesPerView: 2,
              },
            }}
          >
            <div className="flex flex-col gap-5 mx-auto justify-center items-center">
              {el.map((src, index) => (
                <SwiperSlide
                  key={index}
                  className="flex justify-center items-center"
                >
                  <div className="md:h-[120px] h-[100px] w-auto md:w-[310px]">
                    <img
                      alt={`Logo ${index + 1}`}
                      src={src}
                      className="object-cover p-5 slider-img mx-auto"
                    />
                  </div>
                </SwiperSlide>
              ))}
            </div>
          </Swiper>
        ))}
        <div className="mt-5 md:hidden flex justify-center space-x-5">
          <button
            onClick={() => swiperRef.current.slidePrev()}
            className="bg-white border-[1px] hover:scale-105 border-[#14361B] h-12 w-12 rounded-full text-[#14361B] flex justify-center items-center"
          >
            <ChevronLeftIcon style={{ height: "20px", width: "20px" }} />
          </button>
          <button
            onClick={() => swiperRef.current.slideNext()}
            className="bg-[#14361B] hover:scale-105 h-12 w-12 rounded-full text-white flex justify-center items-center"
          >
            <ChevronRightIcon style={{ height: "20px", width: "20px" }} />
          </button>
        </div>
        <div className="hidden my-[30px] md:flex place-self-end place-items-end space-x-5 mx-5">
          <button
            onClick={() => swiperRef.current.slidePrev()}
            className="bg-white border-[1px] hover:scale-105 border-[#14361B] h-12 w-12 rounded-full text-[#14361B] flex justify-center items-center"
          >
            <ChevronLeftIcon style={{ height: "20px", width: "20px" }} />
          </button>
          <button
            onClick={() => swiperRef.current.slideNext()}
            className="bg-[#14361B] hover:scale-105 h-12 w-12 rounded-full text-white flex justify-center items-center"
          >
            <ChevronRightIcon style={{ height: "20px", width: "20px" }} />
          </button>
        </div>
      </div>
    </div>
  );
}

export default OurCustomers;
