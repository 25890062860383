import React from "react";
import OurCustomers from "../components/OurCustomers";
import SisterConcernHero from "../components/SisterConcernHero";
import MainProducts from "../components/MainProducts";
import Achievements from "../../Home/components/Achievements";
import {
  heroPoleStarKnitImage,
  mainProductContent,
  OurCustomersImagesPoleStarKnit,
} from "./components/PoleStarKnitMedias";

const PoleStarKnit = () => {
  return (
    <div className="flex flex-col gap-12">
      <SisterConcernHero
        bgImage={heroPoleStarKnitImage}
        title="Pole Star Knit Composite Ltd."
      />

      <h1 className="text-3xl font-Playfair md:text-5xl font-semibold w-3/4 mx-auto px-2  mt-48 md:mt-20">
        100% Export Oriented Knit Garments Manufacturer Company
      </h1>

      <div className="ml-[30px]">
        <MainProducts content={mainProductContent} />
      </div>

      <OurCustomers
        title="Our Customers"
        imageSet={OurCustomersImagesPoleStarKnit}
      />

      <Achievements />
    </div>
  );
};

export default PoleStarKnit;
