import FlagSlider from "./components/FlagSlider.jsx";
import SixthSection from "./components/SixthSection.jsx";
import "../../Home/components/Home.css";
import React from "react";
import AboutHero from "../components/AboutHero.jsx";
import MessageMD from "./components/MessageMD.jsx";
import PriceQualityShipment from "./components/PriceQualityShipment.jsx";
import {
  heroOurTeamImage,
  organogramImage,
} from "./components/OurTeamMedias.jsx";

const OurTeam = () => {
  return (
    <div className="flex flex-col gap-12">
      <AboutHero
        image={heroOurTeamImage}
        alt={"About - Our Team"}
        title={"About"}
      >
        <p className="">
          About / <strong>Our Team</strong> / Mission & Vision
        </p>
      </AboutHero>

      <MessageMD />

      <PriceQualityShipment />

      <FlagSlider />

      <img
        src={organogramImage}
        alt="Organogarm"
        className="flex justify-center items-center mx-auto md:w-11/12"
      />

      <SixthSection />
    </div>
  );
};

export default OurTeam;
