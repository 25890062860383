import { medialUrl } from "../../../EndPoints";

export const heroPrimeSweaterLTDImage = `${medialUrl}/img/SisterConcern/HeroSisterConcern2.png`;

export const mainProductContent = {
  title: "Main Products",
  outerImage: `${medialUrl}/img/PrimeSweaterLtd/mainProducts/OuterImage.png`,
  arr: [
    {
      img: `${medialUrl}/img/PrimeSweaterLtd/mainProducts/img1.png`,
      innerTitle: "Sweater, Pullover, and Cardigan",
    },
    {
      img: `${medialUrl}/img/PrimeSweaterLtd/mainProducts/img2.png`,
      innerTitle: "Scarf and Caps",
    },
    {
      img: `${medialUrl}/img/PrimeSweaterLtd/mainProducts/img3.png`,
      innerTitle: "Men, Women, and Kids",
      innerText: "Basic & Jacquard Design & Fancy styles",
    },
  ],
};

export const productLineTitle = "Product Line";
export const productLineContent = [
  [
    {
      title: "Product Line",
      text: "Sweater, Pullover, Cardigan, Scarf, Caps, for Men’s /Women’s /Ladies /Girls /Boys, in Basic & Jacquard Design & Fancy styles.",
    },
    {
      title: "Familiar with Types of Yarn",
      text: "100% Cotton, Acrylic, Cashmere like, Rayon, Acrylic Cotton Like, Wool, Viscose, Chenille Acrylic Smiling, Tube yarn & Roving, 50% Acrylic 50% Wool, Cotton Mélange, 60%cotton 40% wool, 70% acrylic 30% wool, all Kind of fancy yarn.",
    },
  ],
  [
    {
      title: "Yarn Sourcing Area (Local)",
      text: "Dayue Bangladesh Ltd., Queen South, Kung King, Ring Shine, Labib Dyeing, Nice Cotton, Fare Trade, Color Master, Shepherd etc.",
    },
    {
      title: "Yarn Sourcing Area (Foreign)",
      text: "Bros, Huafu, UPW, FiliVivi, Jiansghu Gotai, Lugang, Ferney, Filcom etc.",
    },
  ],
];

export const OurCustomersPrimeSweaterLTDImages = [
  [
    `${medialUrl}/brands/vertbaudet.png`,
    `${medialUrl}/brands/orchestra.png`,
    `${medialUrl}/brands/sfrea.png`,
    `${medialUrl}/brands/offtex.png`,
    `${medialUrl}/brands/carry.png`,
    `${medialUrl}/brands/hema.png`,
  ],
  [
    `${medialUrl}/brands/floreal_paris.png`,
    `${medialUrl}/brands/dan_hohn.png`,
    `${medialUrl}/brands/dooa.png`,
    `${medialUrl}/brands/emporio88.png`,
    `${medialUrl}/brands/piazaitalia.png`,
    `${medialUrl}/brands/zebra.png`,
  ],
  [
    `${medialUrl}/brands/mister_lady.png`,
    `${medialUrl}/brands/jeans_fritz.png`,
    `${medialUrl}/brands/john_lewia.png`,
    `${medialUrl}/brands/sorbino.png`,
    `${medialUrl}/brands/suvari.png`,
    `${medialUrl}/brands/corso_da_vinci.png`,
    `${medialUrl}/brands/jeronimo_martins.png`,
  ],
];
