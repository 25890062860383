import React from "react";
import WhyChooseUs from "./components/WhyChooseUs.jsx";
import Achievements from "./components/Achievements.jsx";
import Products from "./components/Products.jsx";
import Hero2 from "./components/Hero2.jsx";
import HomeFirst from "./components/HomeFirst.jsx";
import { medialUrl } from "../EndPoints.jsx";
import OurCustomers from "../SisterConcern/components/OurCustomers.jsx";

const Home = () => {
  return (
    <div className="flex flex-col gap-12">
      <HomeFirst />
      <Hero2 />
      <div>
        <WhyChooseUs />
        <div className="py-5 bg-white">
          <OurCustomers
            title="Our Customers"
            imageSet={OurCustomersHomeImages}
          />
        </div>
        <Products />
      </div>
      <Achievements />
    </div>
  );
};

export default Home;

export const OurCustomersHomeImages = [
  [
    `${medialUrl}/brands/vertbaudet.png`,
    `${medialUrl}/brands/sfrea.png`,
    `${medialUrl}/brands/aldi.png`,
    `${medialUrl}/brands/mango.png`,
    `${medialUrl}/brands/gemo.png`,
    `${medialUrl}/brands/identity.png`,
    `${medialUrl}/brands/inter_sun.png`,
  ],
  [
    `${medialUrl}/brands/mew_look.png`,
    `${medialUrl}/brands/la_halle.png`,
    `${medialUrl}/brands/bel_bo.png`,
    `${medialUrl}/brands/sport_group_denmark.png`,
    `${medialUrl}/brands/Chicco.png`,
  ],
  [
    `${medialUrl}/brands/sergent_major.png`,
    `${medialUrl}/brands/tape_a_Loeil.png`,
    `${medialUrl}/brands/ostin.png`,
    `${medialUrl}/brands/infiknit.png`,
    `${medialUrl}/brands/prenatal.png`,
  ],
  [
    `${medialUrl}/brands/dan_hohn.png`,
    `${medialUrl}/brands/fransa.png`,
    `${medialUrl}/brands/fashion_nova.png`,
    `${medialUrl}/brands/kaffe.png`,
    `${medialUrl}/brands/creame.png`,
  ],
];
