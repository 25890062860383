import React, { useState } from "react";
import Header from "../components/Header";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import TextsmsOutlinedIcon from "@mui/icons-material/TextsmsOutlined";
import { medialUrl } from "../EndPoints";

const Contact = () => {
  // State to store form data
  const [response, setResponse] = useState();
  const [formData, setFormData] = useState({
    access_key: "df97a1bf-e9d1-4c5b-b15e-0ff7989defbc",
    name: "",
    email: "",
    mobile: "",
    address: "",
    message: "",
  });

  // State to store form validation error messages
  const [errorMessage, setErrorMessage] = useState({});

  // Handle input change and reset corresponding error message
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setErrorMessage({ ...errorMessage, [name]: "" });
  };

  // Validate form data
  const validateForm = () => {
    const errors = {};
    const { name, email, mobile, address, message } = formData;

    // Validate name
    if (!name.trim()) errors.name = "Name is required";

    // Validate email
    if (!email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Email address is invalid";
    }

    // Validate mobile number (Bangladeshi format)
    if (!mobile.trim()) {
      errors.mobile = "Mobile number is required";
    } else if (!/^(\+8801|01)[0-9]{9}$/.test(mobile)) {
      errors.mobile = "Mobile number is invalid";
    }

    // Validate address
    if (!address.trim()) errors.address = "Address is required";

    // Validate message
    if (!message.trim()) errors.message = "Message is required";

    // Set error messages
    setErrorMessage(errors);

    // Return validation result
    return Object.keys(errors).length === 0;
  };

  // Handle form submission
  const onSubmit = async (event) => {
    event.preventDefault();

    // Validate form data before submission
    if (!validateForm()) return;

    // Send form data to API
    const res = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(formData),
    }).then((res) => res.json());

    // Set response data
    if (res) setResponse(res);

    // Reset form data on success
    if (res.success) {
      setFormData({
        access_key: "df97a1bf-e9d1-4c5b-b15e-0ff7989defbc",
        name: "",
        email: "",
        mobile: "",
        address: "",
        message: "",
      });
    }
  };

  return (
    <>
      {/* Header Section */}
      <Header
        src={`${medialUrl}/img/contact/ContactHero.png`}
        alt="Contact Us"
        title="Let's Collaborate on Your Next Apparel Venture!"
      />

      {/* Main Content */}
      <main className="container mx-auto mt-8 md:w-4/5">
        {/* Contact Form Section */}
        <section className="py-12">
          <h2 className="text-[55px] font-semibold text-center mb-8 font-Playfair mx-auto">
            Get in Touch!
          </h2>
          <div className="max-w-3xl mx-auto">
            {/* Display response message */}
            {response && (
              <p
                className={`p-3 mb-2 rounded ${
                  response.success ? "bg-green-200" : "bg-red-200"
                }`}
              >
                {response.message}
              </p>
            )}
            <form onSubmit={onSubmit}>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* Render input fields */}
                {[
                  { name: "name", type: "text", placeholder: "Your Full Name" },
                  {
                    name: "email",
                    type: "email",
                    placeholder: "Your Email Address",
                  },
                  {
                    name: "mobile",
                    type: "number",
                    placeholder: "Your Phone Number",
                  },
                  {
                    name: "address",
                    type: "text",
                    placeholder: "Your Address",
                  },
                ].map((field) => (
                  <div key={field.name}>
                    <input
                      type={field.type}
                      name={field.name}
                      placeholder={field.placeholder}
                      onChange={handleChange}
                      value={formData[field.name]}
                      className="shadow-md appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                    {/* Display validation error messages */}
                    {errorMessage[field.name] && (
                      <p className="text-red-500 text-left text-sm">
                        {errorMessage[field.name]}
                      </p>
                    )}
                  </div>
                ))}
                {/* Textarea for message */}
                <div className="col-span-1 md:col-span-2">
                  <textarea
                    name="message"
                    placeholder="Enter Your Message Here..."
                    onChange={handleChange}
                    value={formData.message}
                    className="shadow-md appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                  {errorMessage.message && (
                    <p className="text-red-500 text-left text-sm">
                      {errorMessage.message}
                    </p>
                  )}
                </div>
              </div>
              {/* Submit button */}
              <button className="bg-red-600 text-white py-2 px-10 rounded-full mt-3 flex content-start">
                Submit Message
              </button>
            </form>
          </div>
        </section>

        {/* Map Section */}
        <section className="py-12">
          <div className="max-w-3xl mx-auto">
            <div className="w-full h-[400px]">
              <iframe
                className="w-full h-full"
                src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d11934.242559538276!2d90.38344600705234!3d23.865294742056683!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e1!3m2!1sen!2sbd!4v1719206582959!5m2!1sen!2sbd"
                allowFullScreen=""
                loading="lazy"
              />
            </div>
          </div>
        </section>

        {/* Contact Info Section */}
        <section className="max-w-4xl mx-auto py-12">
          <div className="container mx-auto grid grid-cols-1 lg:grid-cols-3 justify-items-center gap-12 text-center md:text-left">
            {/* Office Address */}
            <ContactInfo
              title="Office Address"
              details="House #14, Road #4, Sector #3, Uttara Model Town, Dhaka-1230, Bangladesh"
            />
            {/* Company Phones */}
            <ContactDetails
              Icon={PhoneOutlinedIcon}
              title="Company Phones"
              details={[
                "+88-02-9819579",
                "+88018-19429405 (Director Kazi Jamanul Haque (Juman))",
              ]}
            />
            {/* Emails */}
            <ContactDetails
              Icon={TextsmsOutlinedIcon}
              title="Pole Star Emails"
              details={[
                "polto@polestargroupbd.net",
                "juman@polestargroupbd.net ",
              ]}
            />
          </div>
        </section>
      </main>
    </>
  );
};

// Contact info component
const ContactInfo = ({ title, details }) => (
  <div className="shadow-lg border-s-4 border-[#ED2025] p-6 leading-8 w-80">
    <h3 className="text-[14px] text-[#41444B] uppercase">{title}</h3>
    <p className="font-semibold">{details}</p>
  </div>
);

// Contact details component with icon
const ContactDetails = ({ Icon, title, details }) => (
  <div className="mt-6 p-6 flex w-80">
    <div className="p-3 bg-[#ED2025] h-[50px] w-[60px] text-white rounded">
      <Icon />
    </div>
    <div className="pl-3">
      <h3 className="pb-1 text-[14px] text-[#41444B] uppercase">{title}</h3>
      {details.map((detail, index) => (
        <p key={index} className="font-semibold">
          {detail}
        </p>
      ))}
    </div>
  </div>
);

export default Contact;
