import { Slide } from "react-reveal";
import { WovenForthSectionContent, woven_video } from "./WovenMedias";
import { medialUrl } from "../../../EndPoints";

const WovenForthSection = () => {
  return (
    <div className="mt-20 bg-[#ED2025] py-10">
      <div className="w-11/12 mx-auto flex flex-col lg:flex-row justify-center items-center gap-5 lg:p-20">
        <Slide left duration={1400}>
          <div className="left w-full lg:w-1/2 text-left px-10">
            <p className="text-white pb-3 font-Playfair text-[42px]">
              More about Woven..
            </p>
            <p className="text-[21px] pb-3 text-white font-Poppins">
              Harnessing the finest materials and employing cutting-edge
              techniques, we specialize in creating woven garments that exude
              sophistication and style.
            </p>
            {WovenForthSectionContent.map((el, i) => {
              return (
                <div className="flex border-b-2 border-white pb-3 flex-row justify-center items-center gap-3">
                  <img src={el.img} alt={el.alt} />
                  <p className="text-[15px] text-white font-Poppins">
                    {el.text}
                  </p>
                </div>
              );
            })}
          </div>
        </Slide>

        <div className="right w-full lg:w-1/2 relative">
          <img
            src={`${medialUrl}/images/top-shape.png`}
            alt="decorator"
            className="absolute"
          />
          <video
            className="mt-20"
            src={woven_video}
            autoPlay
            loop
            muted
            playsInline
          ></video>
          <img
            src={`${medialUrl}/images/bottom-shape.png`}
            alt="decorator"
            className="z-10 mt-[-100px] lg:mt-[-130px] ml-[100px] lg:ml-[350px]"
          />
        </div>
      </div>
    </div>
  );
};

export default WovenForthSection;
