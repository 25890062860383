import React from "react";
import { motion } from "framer-motion";
import HeroLeftContent from "../../components/HeroLeftContent";

const AboutHero = ({ image, title, children }) => {
  const backgroundStyle = {
    backgroundImage: `url(${image})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    transition: "background-image 1s ease-in-out",
  };
  const textVariants = {
    hidden: { opacity: 0, y: "-100%" },
    visible: { opacity: 1, y: "0%" },
    exit: { opacity: 0, y: "100%" },
  };
  return (
    <div
      className="h-[350px] md:h-[400px] w-4/5 mt-16 md:mt-28 mx-auto text-white relative rounded-2xl flex flex-row flex-wrap"
      style={backgroundStyle}
    >
      <div className="absolute top-[8%] md:top-[30%] left-5 md:left-10 flex flex-col gap-6 text-white font-Lato w-[90%] md:w-1/2 text-left">
        <motion.div
          initial="hidden"
          animate="visible"
          exit="exit"
          variants={textVariants}
          transition={{ duration: 1 }}
        >
          <h1 className="text-4xl md:text-5xl font-[Kanit] font-bold md:leading-normal animate-fadeInOut transition delay-150 duration-300 ease-in-out">
            {title}
          </h1>
          {children}
        </motion.div>
      </div>

      <HeroLeftContent />
    </div>
  );
};

export default AboutHero;
