import React from "react";
import { Fade } from "react-reveal";
import { SweaterThirdSectionContent } from "./SweaterMedias";
import {
  RedTriangleFilledDecorator,
  RedTriangleNotFilledDecorator,
} from "../../Woven/components/WovenMedias";

const SweaterThirdSection = () => {
  return (
    <div className="w-[80%] justify-around mx-auto p-4">
      <Fade bottom>
        <div className="flex flex-row flex-wrap gap-8 p-6 text-start">
          {SweaterThirdSectionContent.map((el, i) => {
            return (
              <div
                key={i}
                className={`mb-auto flex flex-1 gap-3 ${
                  i === 0 ? "flex-col" : "flex-col-reverse"
                }`}
              >
                {el.text.map((el1, i1) => {
                  return (
                    <div key={i1}>
                      <p
                        className={`${
                          i === 0
                            ? "text-5xl font-bold mb-4 font-Playfair text-[#435E49]"
                            : "text-4xl font-semibold mt-2 font-Poppins text-black"
                        }`}
                      >
                        {el1.title}
                        <br />
                        {el1.title2}
                      </p>
                      <p className={`${i === 0 ? "text-base" : "text-[20px]"}`}>
                        {el1.description}
                      </p>
                    </div>
                  );
                })}

                <img
                  src={el.src}
                  alt="Types of yarn"
                  className={`w-auto min-w-[320px] h[400px] ${
                    i === 2 ? "md:h-[630px]" : "md:h-[600px]"
                  } object-cover`}
                />
              </div>
            );
          })}
        </div>
      </Fade>

      <div className="flex justify-between bottom-3">
        <img
          className="size-[100px]"
          alt="decorator"
          src={RedTriangleFilledDecorator}
        />
        <img
          className="size-[100px]"
          alt="decorator"
          src={RedTriangleNotFilledDecorator}
        />
      </div>
    </div>
  );
};

export default SweaterThirdSection;
