import { medialUrl } from "../../../EndPoints";

export const heroPoleStarKnitImage = `${medialUrl}/img/SisterConcern/HeroSisterConcern1.png`;
export const mainProductContent = {
  title: "Main Products",
  outerImage: `${medialUrl}/img/PoleStarKnit/MainProducts/OuterImage.png`,
  arr: [
    {
      img: `${medialUrl}/img/PoleStarKnit/MainProducts/img1.png`,
      innerTitle: "Main Fabric Qualities",
      innerText:
        "Single jersey, Heavy jersey, Polo pique, Interlock, Drop needle, Herringbone, Lacoste, Pop com, Terry Waffle, Jacquard, 2/3 Thread fleece (Brushed/Un- brushed), Loop knit, Rib, Striper peach & Emetizing, Collar & Cuff etc. with open width & tubular finish.",
    },
    {
      img: `${medialUrl}/img/PoleStarKnit/MainProducts/img2.png`,
      innerTitle: "Product Line",
      innerText:
        "Basic T-Shirt, Fashionable T-shirt, Fleece, Fleece Terry, Fleece Jacket, Basic Polo Shirt, Fashionable Polo shirt, Dress (Ladies or Kids), Basic Trouser, Fashionable Trouser, Boxer Short, Tank Top/ Ladies Fancy Items",
    },
  ],
};

export const OurCustomersImagesPoleStarKnit = [
  [
    `${medialUrl}/brands/sfrea.png`,
    `${medialUrl}/brands/el_corte_jnglis.png`,
    `${medialUrl}/brands/naviale.png`,
    `${medialUrl}/brands/vertbaudet.png`,
    `${medialUrl}/brands/dan_hohn.png`,
    `${medialUrl}/brands/puntoroma.png`,
    `${medialUrl}/brands/sports_direct.png`,
  ],
  [
    `${medialUrl}/brands/soulcal_co.png`,
    `${medialUrl}/brands/jack_wills.png`,
    `${medialUrl}/brands/foundry.png`,
    `${medialUrl}/brands/fcb.png`,
    `${medialUrl}/brands/machester_united.png`,
    `${medialUrl}/brands/livera.png`,
    `${medialUrl}/brands/prenatal.png`,
  ],
  [
    `${medialUrl}/brands/sergent_major.png`,
    `${medialUrl}/brands/soulcal_co.png`,
    `${medialUrl}/brands/naviale.png`,
    `${medialUrl}/brands/vertbaudet.png`,
    `${medialUrl}/brands/dan_hohn.png`,
    `${medialUrl}/brands/puntoroma.png`,
  ],
];
