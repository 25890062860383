import React from "react";
import {
  cardClasses,
  containerClasses,
  headingClasses,
  hrClasses,
  textClasses,
} from "../BDKnitDesign/components/SecondBdKnitDesign";
import { medialUrl } from "../../EndPoints";

const ProductLine = ({ sectionTitle, content }) => {
  return (
    <div className={containerClasses}>
      <p className="text-4xl font-Playfair md:text-5xl font-bold text-center text-zinc-800 dark:text-zinc-200 my-12">
        {sectionTitle}
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 font-Poppins">
        {content.map((el, i) => {
          return (
            <div key={i} className={`${cardClasses}`}>
              {el[i].division && (
                <h1 className="h-[50px] text-2xl font-semibold">
                  {el[i].division}
                </h1>
              )}
              <div className={`flex flex-col gap-5`}>
                {el.map((el1, i1) => {
                  return (
                    <div key={i1}>
                      <h2 className={headingClasses}>{el1.title} :</h2>
                      <p className={textClasses}>{el1.text}</p>
                    </div>
                  );
                })}
              </div>

              <hr className={hrClasses} />
            </div>
          );
        })}
      </div>
      <img
        src={`${medialUrl}/img/QA/triangle.png`}
        alt="decorator"
        className="size-[150px] ml-[60%] md:ml-[70%] lg:ml-[85%]"
      />
    </div>
  );
};

export default ProductLine;
