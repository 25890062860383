import React from "react";
import { Link } from "react-router-dom";
import { medialUrl } from "../EndPoints";
import callIcon from "../DefaultImages/call.png";
import logo from "../logo.png";

export default function Footer() {
  const page = [
    {
      title: "Products",
      pages: [
        { name: "Woven", path: "/products/woven" },
        { name: "Knit", path: "/products/knit" },
        { name: "Sweater", path: "/products/sweater" },
        { name: "Manufacturing", path: "/manufacture" },
      ],
    },
    {
      title: "Company",
      pages: [
        {
          name: "Pole Star Fashion Design Ltd.",
          path: "/sister-concern/Pole-Star-Fashion-Design-LTD",
        },

        {
          name: "Prime Sweater Ltd.",
          path: "/sister-concern/Prime-Sweater-LTD",
        },
        {
          name: "Pole Star Knit Composite Ltd.",
          path: "/sister-concern/Pole-Star-Knit-Composite-LTD",
        },
        { name: "BD Knit Design Ltd.", path: "/sister-concern/BD-Knit-Design" },
      ],
    },
  ];

  const contact = [
    {
      src: `${medialUrl}/images/address.png`,
      alt: "Location",
      title: "Address",
      textLink:
        "House #14, Road #4, Sector #3, Uttara Model Town, Dhaka-1230, Bangladesh",
      linkPath:
        "https://www.google.com/maps/search/Pole+star+uttara/@23.8628582,90.398064,20.64z?entry=ttu",
    },
    {
      src: callIcon,
      alt: "Call",
      title: "Phone Number",
      textLink: "+88018-19429405",
      textLink2: "(Director Kazi Jamanul Haque (Juman))",
      linkPath: "tel:+88018-19429405",
    },
  ];
  return (
    <div className="mt-20">
      {/* Footer bar */}
      <div className="flex flex-row">
        <div className="md:block hidden h-[1px] w-20 bg-slate-500 ml-auto my-auto" />
        <div className="bg-gradient-to-r from-[#00A070] to-[#14361B] p-4 mx-auto md:mx-0 w-11/12 md:w-1/2 footer-bar border rounded flex flex-row justify-between items-center h-[100px]">
          <p className="text-2xl font-Playfair font-bold text-white">
            Price, Quality & On-time Shipment
          </p>
          <button className="bg-[#ED2025] text-white border rounded-full pl-3 pr-3 pt-2 pb-2">
            Say HELLO!
          </button>
        </div>
        <div className="md:block hidden h-[1px] w-20 bg-slate-500 mr-auto my-auto" />
      </div>

      <div className="pt-20 pb-10 border-gray-300 w-11/12 mx-auto flex flex-col md:flex-row font-Poppins justify-start items-start lg:justify-center lg:items-center gap-5">
        {/* first column */}
        <div className="w-full md:w-1/4">
          <img src={logo} alt="Pole Star Group" className="w-[200px]" />
        </div>

        {/* middle column */}
        <div className="flex flex-row gap-5 justify-between w-full md:w-1/2">
          {page.map((el, i) => {
            return (
              <div key={i} className="w-full text-left">
                <p className="pb-5 font-bold">{el.title}</p>
                {el.pages.map((el1, i1) => {
                  return (
                    <Link key={i1} to={el1.path}>
                      <p className="pb-2 hover:font-semibold hover:underline hover:underline-offset-2">
                        {el1.name}
                      </p>
                    </Link>
                  );
                })}
              </div>
            );
          })}
        </div>

        {/* last column */}
        <div className="grid grid-cols-2 md:flex md:flex-col gap-5 w-full md:w-max ">
          {contact.map((el, i) => {
            return (
              <div key={i} className=" flex flex-col gap-2 text-left">
                <div className="flex flex-row gap-2 items-center">
                  <img src={el.src} alt={el.alt} />
                  <p className="font-bold text-lg">{el.title}</p>
                </div>
                <div className="w-36 md:w-60">
                  <a
                    href={el.linkPath}
                    target="_blank"
                    rel="noreferrer"
                    className="text-base hover:font-semibold hover:underline hover:underline-offset-2"
                  >
                    {el.textLink}
                  </a>
                  {el.textLink2 && <p>{el.textLink2}</p>}
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <p>
        Built by{" "}
        <Link
          className="font-semibold hover:underline hover:underline-offset-2"
          to="kreactech.ca"
        >
          Kreatech
        </Link>
      </p>
    </div>
  );
}
