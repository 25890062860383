import "./App.css";
import { Routes, Route, useLocation } from "react-router-dom";
import Home from "./Home/Home";
import Manufacture from "./Manufacture/Manufacture";
import QA from "./QA/QA";
import Contact from "./Contact/Contact";
import Footer from "./components/Footer";
import Knit from "./Products/Knit/Knit";
import Woven from "./Products/Woven/Woven";
import OurTeam from "./About/OurTeam/OurTeam";
import MissionVision from "./About/MissionVision/MissionVision";
import FloatingButton from "./components/FloatingButton";
import BdKnitDesign from "./SisterConcern/BDKnitDesign/BDKnitDesign";
import PrimeSweaterLTD from "./SisterConcern/PrimeSweaterLTD/PrimeSweaterLTD";
import PoleStarKnit from "./SisterConcern/PoleStarKnit/PoleStarKnit";
import PoleStarFashion from "./SisterConcern/PoleStarFashion/PoleStarFashion";
import NavbarNew from "./components/NavbarNew";
import Sweater from "./Products/Sweater/Sweater";
import { useEffect } from "react";

function App() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [pathname]);
  return (
    <div className="App">
      <NavbarNew />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/our-team" element={<OurTeam />} />
        <Route path="/mission&vision" element={<MissionVision />} />
        <Route path="/products/knit" element={<Knit />} />
        <Route path="/products/woven" element={<Woven />} />
        <Route path="/products/sweater" element={<Sweater />} />
        <Route path="/manufacture" element={<Manufacture />} />
        <Route path="/quality-assurance" element={<QA />} />
        <Route path="/contact" element={<Contact />} />
        <Route
          path="/sister-concern/BD-Knit-Design"
          element={<BdKnitDesign />}
        />
        <Route
          path="/sister-concern/Prime-Sweater-LTD"
          element={<PrimeSweaterLTD />}
        />
        <Route
          path="/sister-concern/Pole-Star-Knit-Composite-LTD"
          element={<PoleStarKnit />}
        />
        <Route
          path="/sister-concern/Pole-Star-Fashion-Design-LTD"
          element={<PoleStarFashion />}
        />
      </Routes>
      <Footer />
      <FloatingButton />
    </div>
  );
}

export default App;
