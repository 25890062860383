import React from "react";
import SisterConcernHero from "../components/SisterConcernHero";
import Hero2PoleStarFashion from "./components/Hero2PoleStarFashion";
import OurCustomers from "../components/OurCustomers";
import ProductLine from "../components/ProductLine";
import Achievements from "../../Home/components/Achievements";
import MainProducts from "../components/MainProducts";
import {
  associateBusinessPoleStarFashion,
  heroPoleStarFashionImage,
  OurCustomersPoleStarFashionImages,
  productLinePoleStarFashionContent,
  productLineTitle,
} from "./components/PoleStarFashionMedia";

const PoleStarFashion = () => {
  return (
    <div className="flex flex-col gap-12">
      <SisterConcernHero
        bgImage={heroPoleStarFashionImage}
        title="Pole Star Fashion Design Ltd."
      />

      <Hero2PoleStarFashion />

      <OurCustomers
        title="Our Customers"
        imageSet={OurCustomersPoleStarFashionImages}
      />

      <ProductLine
        sectionTitle={productLineTitle}
        content={productLinePoleStarFashionContent}
      />
      <Achievements />
      <MainProducts content={associateBusinessPoleStarFashion} />
    </div>
  );
};

export default PoleStarFashion;
