import React from "react";
import { Slide, Zoom } from "react-reveal";

const Hero2PoleStarFashion = () => {
  return (
    <div className="mx-auto mt-28 flex flex-col gap-6 w-full">
      <Zoom duration={1200}>
        <h1 className="text-3xl my-[50px] md:text-6xl font-Playfair font-semibold w-full mx-auto px-2 text-[#1D293F]">
          An Unmatched Apparel Manufacturing Solution
        </h1>
      </Zoom>
      <div className="grid grid-cols-1 mx-[10%] md:grid-cols-2 justify-center items-center gap-5 text-left font-Poppins">
        <Slide bottom duration={1000}>
          <p className="font-bold text-[#ED2025] text-4xl md:text-5xl mx-auto">
            100% Export
            <br />
            Oriented
          </p>
        </Slide>
        <Slide bottom duration={1000}>
          <p className="text-xl md:text-2xl w-4/5">
            Woven Shirt & <br />
            Knit Garments Manufacturer Company.
          </p>
        </Slide>
      </div>
    </div>
  );
};

export default Hero2PoleStarFashion;
