import React, { useState } from "react";
import ArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import ArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { MachineriesImages } from "./ManufactureMedias";

const Machineries = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slidesToShowMedium = 3;
  const slidesToShowSmall = 1;
  const totalSlides = MachineriesImages.length;

  const nextSlide = () => {
    setCurrentSlide((currentSlide + 1) % totalSlides);
  };

  const prevSlide = () => {
    setCurrentSlide((currentSlide - 1 + totalSlides) % totalSlides);
  };

  const getVisibleImagesMedium = () => {
    const visibleImages = [];
    for (let i = 0; i < slidesToShowMedium; i++) {
      visibleImages.push(MachineriesImages[(currentSlide + i) % totalSlides]);
    }
    return visibleImages;
  };

  const getVisibleImagesSmall = () => {
    const visibleImages = [];
    for (let i = 0; i < slidesToShowSmall; i++) {
      visibleImages.push(MachineriesImages[(currentSlide + i) % totalSlides]);
    }
    return visibleImages;
  };

  return (
    <>
      <div className="hidden lg:flex flex-row flex-wrap gap-6 m-auto">
        <button
          onClick={prevSlide}
          className="p-2 hover:bg-success hover:text-white rounded-full m-auto"
        >
          <ArrowLeftIcon />
        </button>

        {getVisibleImagesMedium().map((item, index) => {
          return (
            <img
              key={item.image}
              className={`w-[26%] m-auto`}
              src={item.image}
              alt={item.alt}
            />
          );
        })}

        <button
          onClick={nextSlide}
          className="p-2 hover:bg-success hover:text-white rounded-full m-auto"
        >
          <ArrowRightIcon />
        </button>
      </div>

      <div className="flex flex-col lg:hidden gap-4 m-auto">
        {getVisibleImagesSmall().map((item, index) => {
          return (
            <img
              key={item.image}
              className={`h-[450px] m-auto`}
              src={item.image}
              alt={item.alt}
            />
          );
        })}
        <div className="m-auto flex gap-6">
          <button
            onClick={prevSlide}
            className="p-2 hover:bg-success hover:text-white rounded-full m-auto"
          >
            <ArrowLeftIcon />
          </button>
          <button
            onClick={nextSlide}
            className="p-2 hover:bg-success hover:text-white rounded-full m-auto"
          >
            <ArrowRightIcon />
          </button>
        </div>
      </div>
    </>
  );
};

export default Machineries;
