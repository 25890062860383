import React from "react";
import { Fade } from "react-reveal";

const UsingYarnFabrics = () => {
  return (
    <div className="flex flex-row w-[80%] justify-around items-center mx-auto p-4">
      <Fade left>
        <p className="font-Poppins text-[#ED2025] w-1/2 font-semibold text-5xl">
          Using Yarn/
          <br />
          Fabrics Type
        </p>
      </Fade>
      <Fade right>
        <p className="font-Poppins text-left text-2xl my-auto text-[#5A5A5A] w-1/2">
          100% Cotton, 100% Cotton Y/D, 65 % Polyesters, 35 % Cotton, 100 %
          Cotton Y/D Twill Filament, Input Fabric Like Viscose etc.
        </p>
      </Fade>
    </div>
  );
};

export default UsingYarnFabrics;
