import React from "react";
import { motion } from "framer-motion";
const Title = ({ title }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <div className="mt-44 md:mt-28 m-auto flex flex-col justify-center items-center">
        <div className="h-1 w-20 bg-[#ED2025]"></div>
        <p className="text-3xl  text-[#1A202C] font-Playfair w-max p-2">
          {title}
        </p>
      </div>
    </motion.div>
  );
};

export default Title;
