import React from "react";

const MainProducts = ({ content }) => {
  return (
    <div className="grid my-[60px] grid-cols-1 lg:grid-cols-3 text-left gap-6 justify-center  mx-auto w-10/12">
      <div className="flex flex-col gap-3">
        <h1 className="font-Playfair font-bold text-6xl my-5">
          {content.title}
        </h1>
        <img src={content.outerImage} alt="" className="w-1/2 md:w-80" />
      </div>

      <div className="flex flex-col gap-3 justify-between col-span-2 font-Poppins">
        {content.arr.map((el, i) => {
          return (
            <div
              key={i}
              className={`flex gap-2 ${
                i % 2 === 0 ? "flex-row" : "flex-row-reverse"
              }`}
            >
              <div className="flex flex-col gap-1 justify-center">
                <p className=" font-bold my-[20px] text-2xl md:text-3xl md:w-[200px]">
                  {el.innerTitle}
                </p>
                <p>{el.innerText}</p>
              </div>
              <img src={el.img} alt="" className="w-40 md:w-72 h-40 md:h-72" />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MainProducts;
