import React from "react";
import OurCustomers from "../../SisterConcern/components/OurCustomers";
import { machineriesImages } from "./ManufactureMedias";

const Companies = () => {
  return (
    <div className="container mx-auto">
      <h2 className="text-5xl font-bold mb-4 text-[#1D293F]  font-Playfair">
        Machineries in Plant
      </h2>

      <OurCustomers imageSet={machineriesImages} />
    </div>
  );
};

export default Companies;
