import React from "react";
import { Link } from "react-router-dom";
import { medialUrl } from "../../EndPoints";

const SisterConcernHeroLeftContent = () => {
  return (
    <div className="absolute bottom-16 right-5 text-left flex flex-row gap-1 text-white backdrop-blur p-2 rounded-xl">
      <img
        src={`${medialUrl}/img/Home/home-hero.jpg`}
        className="rounded-full w-10 h-10"
        alt=""
      />
      <div>
        <p className="font-semibold">
          Setting Trends, <br />
          Setting Standards.
        </p>
        <Link to="/" className="font-bold underline underline-offset-1">
          Know More!
        </Link>
      </div>
    </div>
  );
};

export default SisterConcernHeroLeftContent;
