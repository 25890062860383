import { medialUrl } from "../../../EndPoints";
import { ApartmentIcon } from "../../Woven/components/WovenMedias";

// Sweater
export const SweaterHeroImage = `${medialUrl}/img/Sweater/sweater-hero.jpeg`;

export const sweaterStatCounter = [
  {
    sign: "ACCORD",
    text: "Approved",
    icon: ApartmentIcon,
  },
  {
    number: "0.0",
    sign: "%",
    text: "Re-work & Re-check",
    icon: ApartmentIcon,
  },
];

const Sweater = `${medialUrl}/img/Sweater/sweater.png`;
const Pullover = `${medialUrl}/img/Sweater/pullover.png`;
const Cardigan = `${medialUrl}/img/Sweater/cardigan.png`;
const Scarf = `${medialUrl}/img/Sweater/scarf.png`;
const Caps = `${medialUrl}/img/Sweater/cap.png`;
export const sweaterArray = [
  {
    img: Sweater,
    text: "Sweater",
  },
  {
    img: Pullover,
    text: "Pullover",
  },
  {
    img: Cardigan,
    text: "Cardigan",
  },
  {
    img: Scarf,
    text: "Scarf",
  },
  {
    img: Caps,
    text: "Caps",
  },
];

// SweaterFirstSection
const SweaterFirstSectionContent1 = [
  `${medialUrl}/img/Sweater/linking_product_bg.png`,
  `${medialUrl}/img/Sweater/production_quality_icon.png`,
];
const SweaterFirstSectionContent2 = [
  `${medialUrl}/img/Sweater/production_quality_bg.png`,
  `${medialUrl}/img/Sweater/fire_extinguisher_icon.png`,
];
const SweaterFirstSectionContent3 = [
  `${medialUrl}/img/Sweater/knitting_area_bg.png`,
  `${medialUrl}/img/Sweater/knitting_area_icon.png`,
];
const SweaterFirstSectionContent4 = [
  `${medialUrl}/img/Sweater/linking_product_bg.png`,
  `${medialUrl}/img/Sweater/linking_product_icon.png`,
];
export const SweaterFirstSectionContent = [
  {
    bgDecoratorSrc: SweaterFirstSectionContent1[0],
    icon: SweaterFirstSectionContent1[1],
    title: "70,000 sq. ft",
    text: "Production & Quality Space",
  },
  {
    bgDecoratorSrc: SweaterFirstSectionContent2[0],
    icon: SweaterFirstSectionContent2[1],
    title: "150 +",
    text: "Fire Extinguishers",
  },
  {
    bgDecoratorSrc: SweaterFirstSectionContent3[0],
    icon: SweaterFirstSectionContent3[1],
    title: "14,000 sq. ft",
    text: "Knitting Production Area",
  },
  {
    bgDecoratorSrc: SweaterFirstSectionContent4[0],
    icon: SweaterFirstSectionContent4[1],
    title: "10,000 sq. ft",
    text: "Linking Production Area",
  },
];

// SweaterThirdSection
const SweaterThirdSectionContent1 = [
  `${medialUrl}/img/Sweater/types_of_yarn.png`,
];
const SweaterThirdSectionContent2 = [`${medialUrl}/img/Sweater/local_yarn.png`];
const SweaterThirdSectionContent3 = [
  `${medialUrl}/img/Sweater/foreign_yarn.png`,
];
export const SweaterThirdSectionContent = [
  {
    text: [
      {
        title: "Types of",
        title2: "Yarn",
        description:
          "100% Cotton, Acrylic, Cashmere-like, Rayon, Acrylic Cotton-like, Wool, Viscose, Chenille Acrylic Strings, Tube Yarn and Roving, 50% Acrylic, 50% Wool, Cotton Melange, 65% Cotton, 45% Wool, 70% Acrylic, 30% Wool & All kinds of fancy yarn",
      },
    ],
    src: SweaterThirdSectionContent1,
  },
  {
    text: [
      {
        title: "Local Yarn",
        title2: "Sourcing",
        description:
          "Dayue Bangladesh Ltd., Queen South, Kung King, Ring Shine, Laibie Dyeing, Cotton, Fare Trade, Color Master, Shepherd etc.",
      },
    ],
    src: SweaterThirdSectionContent2,
  },
  {
    text: [
      {
        title: "Foreign",
        title2: "Sourcing",
        description:
          "Bros, Huafu, UPWF, FillW, Jiangsu Gotal, Luggang, Ferney, Filcom etc.",
      },
    ],
    src: SweaterThirdSectionContent3,
  },
];
