import React from "react";
import SweaterThirdSection from "./componets/SweaterThirdSection";
import SweaterFirstSection from "./componets/SweaterFirstSection";
import Title from "../components/Title";
import HeroSection from "../components/HeroSection";
import ProductsExamples from "../components/ProductsExamples";
import {
  SweaterHeroImage,
  sweaterArray,
  sweaterStatCounter,
} from "./componets/SweaterMedias";
import OurCustomers from "../../SisterConcern/components/OurCustomers";
import { OurCustomersHomeImages } from "../../Home/Home";

const Sweater = () => {
  const heroTitle =
    "Where dedication meets sustainability. Passionately Crafted, Ethically Sourced Sweaters.";
  return (
    <div className="flex flex-col gap-12">
      <HeroSection
        bgImage={SweaterHeroImage}
        statCounter={sweaterStatCounter}
        title={heroTitle}
      />
      <Title title="Sweater Division" />
      <SweaterFirstSection />
      <ProductsExamples content={sweaterArray} />
      <div className="py-5 bg-white">
        <OurCustomers title="Our Customers" imageSet={OurCustomersHomeImages} />
      </div>
      <SweaterThirdSection />
    </div>
  );
};

export default Sweater;
