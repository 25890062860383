import { medialUrl } from "../../../EndPoints";

export const heroBDKnitImage = `${medialUrl}/img/SisterConcern/HeroSisterConcern1.png`;
export const associateBusinessBDKnit = {
  title: "Our Products",
  outerImage: `${medialUrl}/img/BDKnitLtd/AssociateBusiness/OuterImage.png`,
  arr: [
    {
      img: `${medialUrl}/img/BDKnitLtd/AssociateBusiness/img1.png`,
      innerTitle: "",
    },
    {
      img: `${medialUrl}/img/BDKnitLtd/AssociateBusiness/img2.png`,
      innerTitle: "",
    },
    {
      img: `${medialUrl}/img/BDKnitLtd/AssociateBusiness/img3.png`,
      innerTitle: "",
    },
  ],
};

export const OurCustomersBDKnitImages = [
  [
    `${medialUrl}/brands/el_corte_jnglis.png`,
    `${medialUrl}/brands/sfrea.png`,
    `${medialUrl}/brands/tao.png`,
    `${medialUrl}/brands/piazaitalia.png`,
    `${medialUrl}/brands/offtex%20%282%29.png`,
    `${medialUrl}/brands/puntoroma.png`,
  ],
  [
    `${medialUrl}/brands/soulcal_co.png`,
    `${medialUrl}/brands/machester_united.png`,
    `${medialUrl}/brands/esotiq.png`,
    `${medialUrl}/brands/livera.png`,
    `${medialUrl}/brands/bou.png`,
  ],
];
