import React from "react";
import SisterConcernHero from "../components/SisterConcernHero";
import MainProducts from "../components/MainProducts";
import ProductLine from "../components/ProductLine";
import Achievements from "../../Home/components/Achievements";
import OurCustomers from "../components/OurCustomers";
import {
  heroPrimeSweaterLTDImage,
  mainProductContent,
  OurCustomersPrimeSweaterLTDImages,
  productLineContent,
  productLineTitle,
} from "./components/PrimeSweaterLTDMedias";

const PrimeSweaterLTD = () => {
  return (
    <div className="flex flex-col gap-6 relative">
      <SisterConcernHero
        bgImage={heroPrimeSweaterLTDImage}
        title="Prime Sweater Ltd."
      />

      <h1 className="text-3xl font-Playfair md:text-5xl font-semibold w-3/4 mx-auto px-2 mt-16">
        A reliable and compliant manufacturer based in Bangladesh and is a
        project of Pole Star Group.
      </h1>

      <MainProducts content={mainProductContent} />

      <ProductLine
        sectionTitle={productLineTitle}
        content={productLineContent}
      />

      <OurCustomers
        title="Our Customers"
        imageSet={OurCustomersPrimeSweaterLTDImages}
      />

      <Achievements />
    </div>
  );
};

export default PrimeSweaterLTD;
