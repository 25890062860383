import "./Home.css";
import { Slide, Zoom } from "react-reveal";

const Hero2 = () => {
  return (
    <div className="mx-auto mt-36 md:mt-24 flex flex-col gap-6 w-11/12 md:w-3/4">
      <Zoom duration={1200}>
        <p className="font-bold text-[50px] text-[#1D293F] font-Playfair">
          An Unmatched Apparel Manufacturing Solution
        </p>
      </Zoom>
      <div className="grid grid-cols-1 md:grid-cols-2 justify-center items-center gap-5 text-left">
        <Slide bottom duration={1000}>
          <div className="font-bold text-[#ED2025] text-4xl md:text-5xl mx-auto tracking-wide flex flex-col gap-3">
            <p>We Export</p>
            <p>Worldwide</p>
          </div>
        </Slide>
        <Slide bottom duration={1000}>
          <p className="text-xl md:text-2xl md:w-4/5">
            At Pole Star Group, we stand as a beacon of excellence in the
            garment industry, offering a compelling blend of craftsmanship,
            innovation, and commitment. Choosing us means selecting a partner
            dedicated to your success.
          </p>
        </Slide>
      </div>
    </div>
  );
};

export default Hero2;
