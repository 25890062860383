import React from "react";
import { Fade } from "react-reveal";
import {
  ThirdMissionVisionAbout,
  garmentWorkingEnv,
} from "./MissionVisionMedias";

const ThirdMissionVision = () => {
  return (
    <div className="flex flex-row">
      <img
        className="hidden lg:block w-1/3"
        src={garmentWorkingEnv}
        alt="Random"
      />

      <div className="grid grid-cols-1 md:grid-cols-3 col-span-2">
        {ThirdMissionVisionAbout.map((item, i) => {
          return (
            <div
              style={{ backgroundImage: `url(${item.img})` }}
              className="bg-cover"
            >
              <div
                className={`font-Lato w-full h-full bg-opacity-50 p-5 lg:p-8 text-white font-Lato flex flex-col gap-4 md:gap-20 ${
                  i % 2 === 0 ? "bg-[#14361B]" : "bg-[#ED2025]"
                }`}
              >
                <Fade top={i === 0 || i === 2} bottom={i === 1}>
                  <p className="text-3xl text-left font-semibold">
                    {item.title}
                  </p>
                  <p className="text-left mb-0 md:mb-20">{item.description}</p>
                </Fade>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ThirdMissionVision;
