import React, { useState } from "react";
import { Fade } from "react-reveal";
import { Scrollama, Step } from "react-scrollama";

const ProductsExamples = ({ content }) => {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  const handleStepChange = (step) => {
    setCurrentStepIndex(step.data);
  };

  return (
    <Scrollama onStepChange={handleStepChange}>
      {content.map((item, index) => (
        <Step key={index} data={index}>
          <Fade left={index % 2 === 0} right={index % 2 !== 0}>
            <div
              className={`flex flex-wrap lg:flex-nowrap ${
                index % 2 === 0 ? "md:flex-row-reverse" : "md:flex-row"
              } font-Poppins font-semibold p-2 text-2xl`}
            >
              <img
                className={`${index % 2 === 0 ? "mr-auto" : "ml-auto"}`}
                src={item.img}
                alt=""
              />
              <p className="m-auto">{item.text}</p>
            </div>
          </Fade>
        </Step>
      ))}
    </Scrollama>
  );
};

export default ProductsExamples;
