import React from "react";
import { medialUrl } from "../../../EndPoints";

export const containerClasses = "container mx-auto px-4 py-0 relative";
export const cardClasses =
  "bg-zinc-100 dark:bg-zinc-800 p-6 rounded-lg shadow-md text-start text-xl flex flex-col justify-between";
export const headingClasses =
  "text-xl font-bold text-zinc-600 dark:text-zinc-300 mb-2";
export const textClasses = "text-zinc-600 dark:text-zinc-400";
export const hrClasses = "mt-4 border-zinc-300 dark:border-zinc-600";

const ProductCard = ({ title, content }) => {
  return (
    <div className={cardClasses}>
      <h2 className={headingClasses}>{title}</h2>
      <p className={textClasses}>{content}</p>
      <hr className={hrClasses} />
    </div>
  );
};

const SecondBdKnitDesign = () => {
  const content = [
    {
      title: "Product Line",
      text: "Basic T-Shirt, Fashionable T-shirt, Fleece Jacket, Basic Polo Shirt, Fashionable Polo shirt, Dress (Ladies or Kids), Basic Trouser, Fashionable Trouser, Boxer Short, Tank Top/ Ladies Fancy Items",
    },
    {
      title: "Main Fabric Qualities",
      text: "Single jersey, Heavy jersey, Polo pique, Interlock, Drop needle, Herringbone, Lacoste, Popcorn, Waffle, Jacquard, 2/3 Thread fleece (Brushed/Un-brushed), Loop knit, Rib, Striper peach & Emarizing, Collar & Cuff etc. with open width & tubular finish",
    },
  ];
  return (
    <div className={containerClasses}>
      <h1 className="text-4xl font-Playfair md:text-5xl font-bold text-center text-zinc-800 dark:text-zinc-200 my-12 mt-48 md:mt-20">
        100% Export Oriented Woven Shirt & Knit Garments Manufacturer Company
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 font-Poppins">
        {content.map((el, i) => {
          return <ProductCard key={i} title={el.title} content={el.text} />;
        })}
      </div>
      <img
        src={`${medialUrl}/img/QA/triangle.png`}
        alt="decorator"
        className="size-[150px] ml-[60%] md:ml-[70%] lg:ml-[85%]"
      />
    </div>
  );
};

export default SecondBdKnitDesign;
