import { medialUrl } from "../../../EndPoints";

// FirstMissionVision
const VisionImage = `${medialUrl}/img/About/MissionVision/polestar-vision-pic.png`;
const MissionImage = `${medialUrl}/img/About/MissionVision/woolen-dress.jpg`;
export const FristMissionVisionService = [
  {
    image: VisionImage,
    title: "Vision",
    alt: "polestar-vision",
    description:
      "Our vision is not merely to be an apparel manufacturing solution but to be an unparalleled force in the industry, setting new benchmarks and redefining excellence. We aspire to stand out as a beacon of innovation, commitment, and quality in the realm of apparel manufacturing.",
  },
  {
    image: MissionImage,
    title: "Mission",
    alt: "woolen-dress",
    description:
      "Our mission is to produce a diverse array of apparels that not only meet but exceed expectations. We are dedicated to offering exceptional pricing without compromising on quality. Innovation is at the heart of our mission, as we strive to bring forth cutting-edge designs that captivate and inspire",
  },
];

// SecondMissionVision
const ManufacturingImage = `${medialUrl}/img/About/MissionVision/Manufacturing.png`;
const MOQImage = `${medialUrl}/img/About/MissionVision/MOQ.png`;
const ProcessQualityImage = `${medialUrl}/img/About/MissionVision/ProcessQuality.png`;
const DesignDevelopmentImage = `${medialUrl}/img/About/MissionVision/DesignDev.png`;
const ERPImage = `${medialUrl}/img/About/MissionVision/ERP.png`;

export const SecondMissionVisionContent = [
  {
    image: ManufacturingImage,
    title: "Manufacturing",
    text: "Our dedicated teams ensure high quality and craftsmanship standard remains consistent whether order quantity is 1,000 or 100,000 pieces.",
  },
  {
    image: MOQImage,
    title: "MOQ",
    text: "Minimum order quantity is 2,000 pieces per color, per style. A large order quantity allows higher efficiency at a reduced manufacturing cost.",
  },
  {
    image: ProcessQualityImage,
    title: "Process & Quality",
    text: "A strict regime of internal audits, critical process check, 3rd party testing on fabric / accessories / garments and Pre-final Inspection @ AQL 2.5 are done prior to shipment.",
  },
  {
    image: DesignDevelopmentImage,
    title: "Design & Development",
    text: "We can assist customer creating fits and design for garments. We offer choices of fabrication, wide range of accessories and different packaging method: flat pack, boxed pack, hanging garments, etc.",
  },
  {
    image: ERPImage,
    title: "ERP (Enterprise Resource Planning)",
    text: "Our core functions are based on Enterprise Resource Planning (ERP). We strive continuously to reduce wastage of materials and increase production efficiency.",
  },
];

// ThirdMissionVision
export const garmentWorkingEnv = `${medialUrl}/img/About/MissionVision/garments-environment.jpg`;
const CommitmentImage = `${medialUrl}/img/Home/polestar_close_hanging_clothing_rack_in_a_shop.jpg`;
const EfficiencyImage = `${medialUrl}/img/Home/polestar_colorful_women_dresses_in_retail.jpg`;
const ComplianceImage = `${medialUrl}/img/Home/polestar_closeup_colorful_hanging_t_shirts.jpg`;

export const ThirdMissionVisionAbout = [
  {
    img: CommitmentImage,
    title: "Commitment",
    description:
      "We value our commitments for on time delivery, quality and sustainability.",
  },
  {
    img: EfficiencyImage,
    title: "Efficiency",
    description:
      "We set high efficiency standard to run our core functions to excel.",
  },
  {
    img: ComplianceImage,
    title: "Compliance",
    description:
      "We test our social & technical compliance rigorously & routinely for accountability & transparency.",
  },
];
