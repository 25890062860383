import React from "react";
import Header from "../components/Header";
import Manufacturing from "./components/Manufacturing";
import FactoryBuilding from "./components/FactoryBuilding";
import Machineries from "./components/Machineries";
import Companies from "./components/Companies";
import { heroManufactureImage } from "./components/ManufactureMedias";

const Manufacture = () => {
  return (
    <div className="flex flex-col gap-12">
      <Header
        src={heroManufactureImage}
        alt={"Manufacture - Behind the Seams"}
        title={"Behind the Seams:"}
        title2=" Where Innovation Meets Craftsmanship in Apparel Manufacturing"
      />

      <Manufacturing />
      <FactoryBuilding />
      <Machineries />
      <Companies />
    </div>
  );
};

export default Manufacture;
