import React from "react";
import { Fade } from "react-reveal";
import { FristMissionVisionService } from "./MissionVisionMedias";

const FirstMissionVision = () => {
  return (
    <div className="m-auto text-white font-Lato text-left">
      {FristMissionVisionService.map((el, i) => {
        return (
          <div key={i}>
            <div className="lg:grid hidden lg:grid-cols-2 w-full md:w-[70%] lg:w-[80%] m-auto">
              <Fade left={i % 2 === 0} right={i % 2 !== 0}>
                <img
                  className={`${i % 2 !== 0 && "hidden"}`}
                  src={el.image}
                  alt={el.alt}
                />
                <div className="bg-[#ED2025] flex flex-col gap-2 p-[18px] justify-center">
                  <p className="text-[28px] text-[#14361B] font-bold">
                    {el.title}
                  </p>
                  <p className="text-[15px]">{el.description}</p>
                </div>
                <img
                  className={`${i % 2 === 0 && "hidden"}`}
                  src={el.image}
                  alt={el.alt}
                />
              </Fade>
            </div>
            <div className="flex flex-col lg:hidden w-5/6 m-auto">
              <Fade left>
                <img className="" src={el.image} alt={el.alt} />
              </Fade>
              <Fade right>
                <div className="bg-[#ED2025] flex flex-col gap-3 p-4">
                  <p className="text-[28px] text-[#14361B] font-bold">
                    {el.title}
                  </p>
                  <p className="text-[17px] font-[Lato]">{el.description}</p>{" "}
                </div>
              </Fade>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default FirstMissionVision;
