import React from "react";
import achievementImg from "../../DefaultImages/achievement.jpg";

export default function Achievements() {
  return (
    <div className="w-11/12 mx-auto mt-15 px-[5%] flex flex-col gap-5">
      {/* <Slide right duration={1400}> */}
      <p className="border-b-2 text-left border-gray-200 text-[48px] pt-10 pb-3 font-Playfair">
        Our Compliance Achievements
      </p>
      {/* </Slide> */}
      <div className="grid grid-cols-1 md:grid-cols-2 items-center justify-around">
        {/* <div className="">
          <video
            className=""
            src={`${medialUrl}/videos/polestar_complaince_logo.mp4`}
            autoPlay
            loop
            muted
            playsInline
          ></video>
          
        </div> */}
        <img src={achievementImg} alt="Achivements" className="ml-auto" />
        <div className="flex flex-col gap-5 justify-center items-center text-left text-white lg:text-xl bg-[#ED2025] w-full h-full p-5">
          {/* <Slide right duration={1400}> */}
          <p className="font-bold">
            Our dynamic Ethical Sourcing and Sustainability team brings
            extensive expertise, ensuring the highest level of compliance and
            workplace safety across all our factories.
          </p>
          <p>
            With a highly dynamic Ethical Sourcing and Sustainability team, we
            prioritize maximum compliance and workplace safety standards across
            our entire factory network.
          </p>
          {/* </Slide> */}
        </div>
      </div>
    </div>
  );
}
