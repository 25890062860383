import React from "react";
import UsingYarnFabrics from "../components/UsingYarnFabrics";
import Title from "../components/Title";
import ProductsExamples from "../components/ProductsExamples";
import HeroSection from "../components/HeroSection";
import {
  KnitHeroImage,
  knitArray,
  knitStatCounter,
} from "./components/KnitMedias";

const Knit = () => {
  const heroTitle =
    "Meticulously crafted knitwear, designed for unrivaled coziness and timeless elegance";

  return (
    <div className="flex flex-col gap-12">
      <HeroSection
        bgImage={KnitHeroImage}
        statCounter={knitStatCounter}
        title={heroTitle}
      />
      <Title title="Knit Division" />
      <ProductsExamples content={knitArray} />
      <UsingYarnFabrics />
    </div>
  );
};

export default Knit;
