import React from "react";
import { FactoryBuildingContent } from "./ManufactureMedias";

const FactoryBuilding = () => {
  return (
    <div className="container mx-auto">
      <h2 className="text-5xl font-bold mb-4 text-[#0F0E11] font-Playfair">
        Factory Building
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-4 gap-6 ">
        {FactoryBuildingContent.map((el, i) => {
          return (
            <div
              key={i}
              className="flex flex-col items-center gap-3 justify-center p-6 rounded-lg hover:shadow"
            >
              <div className="h-[200px] flex justify-center items-center">
                <img
                  src={el.src}
                  alt={el.title}
                  className="w-[320px] md:w-full"
                />
              </div>
              <p className="text-gray-700 font-semibold md:text-lg">
                {el.title}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FactoryBuilding;
