import React from "react";
import { SecondMissionVisionContent } from "./MissionVisionMedias";
const SecondMissionVision = () => {
  return (
    <div className="flex flex-row flex-wrap gap-5 justify-center w-11/12 mx-auto my-20">
      {SecondMissionVisionContent.map((el, i) => {
        return (
          <div key={i} className="flex flex-col gap-3 w-56 text-left">
            <img src={el.image} alt={el.title} className="w-20" />
            <p className="font-semibold text-2xl">{el.title}</p>
            <p className="text-lg">{el.text}</p>
          </div>
        );
      })}
    </div>
  );
};

export default SecondMissionVision;
