import React from "react";
import { Slide } from "react-reveal";
import { MDImage } from "./OurTeamMedias";

const MessageMD = () => {
  return (
    <>
      <p className="text-[#B42A28] font-Poppins text-xl">ABOUT</p>
      <div className="w-11/12 mx-auto flex flex-col lg:flex-row justify-center items-center lg:items-start gap-2">
        <div className="md:hidden block">
          <Title />
        </div>

        <div className="left lg:flex lg:flex-col lg:justify-center lg:items-center w-full lg:w-1/2">
          <img
            alt="Kazi Ahsanul Haque (Polto)"
            src={MDImage}
            className="h-[320px] md:h-[590px] mx-auto"
          />
        </div>
        <Slide right duration={1000}>
          <div className="right w-full lg:w-1/2 text-left">
            <div className="hidden md:block">
              <Title />
            </div>

            <div className="bg-[#F9F9F9] py-4 px-6 font-Poppins flex flex-col gap-4">
              <p className="text-[17px]">
                Pole Star Group lives by the ethos of 'Customer First, Quality
                Always,' transcending mere slogan status to embody our core
                philosophy. We are dedicated to hard work, sustainable
                expansion, and human resources development, contributing to the
                national economy and GDP. Our commitment extends beyond
                profit-making, focusing on factors such as job creation and
                national economic contributions. The evolution from Pole Star
                Fashion to the current Pole Star Group of six companies
                signifies our journey. While acknowledging our success, there
                came a pivotal moment when my vision expanded, emphasizing how
                our growth could benefit others and contribute to the national
                economy. I call upon every member of our group to work
                diligently, fostering a sense of belonging to overcome future
                challenges. It's not just about what Pole Star Group has done
                for you but what you've done for Radiance. Wishing you success
                and enduring partnerships.
              </p>
              <p className="text-[20px] font-semibold">
                Kazi Ahsanul Haque (Polto), M.D
                <br /> Kazi Jamanul Haque (Juman), Director <br /> Mahajabeen
                Noor, Director
              </p>
            </div>
          </div>
        </Slide>
      </div>
    </>
  );
};

export default MessageMD;

const Title = () => {
  return (
    <p className="text-[45px] font-Playfair font-semibold">
      Message from <br></br>Managing Director & Director
    </p>
  );
};
