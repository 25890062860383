import React from "react";
import { Slide, Zoom } from "react-reveal";
import "../components/Home.css";
import { Link } from "react-router-dom";
import { medialUrl } from "../../EndPoints";

const Products = () => {
  const content = [
    {
      title: "Knit Division",
      text: [
        { title1: "Production Sewing Line:", boldText1: "30 Lines" },
        {
          title1: "Product Line of Knit Item:",
          boldText1:
            "Ladies Long Sleeve T-Shirt, Ladies Tank Top, Ladies S/S T-Shirt, Ladies Frog, Polo Shirt, Bottom, Night Dress, Kids Item, Yoga, viscose etc.",
        },
        {
          title1: "Using Yarn/ Fabrics type:",
          boldText1:
            "100% Cotton, 100% Cotton Y/D, 65 % polyesters 35 % Cotton, 100 % Cotton Y/D Twill Filament, Input Fabric Like Viscose etc.",
        },
      ],
      linkText: "See more",
      to: "/knit",
    },
    {
      title: "Woven Division",
      text: [
        { title1: "Production Sewing Line:", boldText1: "08 Lines" },
        {
          title1: "Product Line of Woven Item:",
          boldText1:
            "Men’s, Ladies & Children Wear (Basic Shirt & Classical Shirt) Tops & Bottom",
        },
        {
          title1: "Using Yarn/ Fabrics type:",
          boldText1:
            "100% Cotton, 100% Cotton Y/D, 65 % polyesters 35 % Cotton, 100 % Cotton Y/D Twill Filament, Input Fabric like Viscose etc.",
        },
      ],
      linkText: "See more",
      to: "/woven",
    },
    {
      title: "Sweater Division",
      text: [
        {
          title1:
            "The main products of the company are all kinds of knitwear and sweaters like",
          boldText1:
            "Sweater, Pullover, Cardigan, Scarf, Caps, for Men/Women/Kids, in Basic & Jacquard Design & Fancy styles.",
        },
        {
          title1:
            " The plant has been designed as per the requirement of global fashion buyers and it is ",
          boldText1: "ACCORD",
          extra: " approved.",
        },
      ],
      linkText: "See more",
      to: "/sweater",
    },
  ];

  const backgroundStyle = {
    backgroundImage: `url(${`${medialUrl}/images/ops10.png`})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    transition: "background-image",
  };
  return (
    <div style={backgroundStyle} className="hello flex flex-col gap-5">
      <Zoom duration={1200}>
        <p className="text-white font-semibold text-6xl md:text-[64px] font-Playfair mt-5">
          Our Products
        </p>
      </Zoom>

      <div className="w-11/12 mx-auto flex flex-row flex-wrap justify-center gap-10 py-10 text-left">
        {content.map((el, i) => {
          return (
            <Slide key={i} bottom duration={800}>
              <div className="w-[350px]">
                <span className="ml-[147.5px] rounded-full text-white w-12 h-12 p-1 inline-flex justify-center items-center bg-[#ED2025] border-8 border-[#14361B]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                  >
                    <path d="M 28.28125 6.28125 L 11 23.5625 L 3.71875 16.28125 L 2.28125 17.71875 L 10.28125 25.71875 L 11 26.40625 L 11.71875 25.71875 L 29.71875 7.71875 Z"></path>
                  </svg>
                </span>
                <div className="bg-[#14361B] mt-[-20px] text-white p-7 rounded-2xl box-shadow flex flex-col gap-5">
                  <p className="font-bold text-xl">{el.title}</p>

                  <div className="flex flex-col gap-3">
                    {el.text.map((el1, i1) => {
                      return (
                        <p key={i1}>
                          <span className="font-light">{el1.title1}</span>{" "}
                          <span className="font-semibold">{el1.boldText1}</span>
                          {el1.extra && <span>{el1.extra}</span>}
                        </p>
                      );
                    })}
                  </div>

                  <Link
                    to={el.to}
                    className="w-max px-3 py-2 rounded-full text-white font-semibold bg-[#ED2025] hover:shadow hover:shadow-black"
                  >
                    {el.linkText}
                  </Link>
                </div>
              </div>
            </Slide>
          );
        })}
      </div>
    </div>
  );
};

export default Products;
