import React from "react";
import WovenForthSection from "./components/WovenForthSection";
import Title from "../components/Title";
import UsingYarnFabrics from "../components/UsingYarnFabrics";
import ProductsExamples from "../components/ProductsExamples";
import HeroSection from "../components/HeroSection";
import {
  WovenHeroImage,
  wovenArray,
  wovenStatCounter,
} from "./components/WovenMedias";

const Woven = () => {
  const heroTitle =
    "Crafted with precision, woven with passion - discover timeless elegance in every thread";
  return (
    <div className="flex flex-col gap-12">
      <HeroSection
        bgImage={WovenHeroImage}
        statCounter={wovenStatCounter}
        title={heroTitle}
      />
      <Title title="Woven Division" />
      <ProductsExamples content={wovenArray} />
      <UsingYarnFabrics />
      <WovenForthSection />
    </div>
  );
};

export default Woven;
