import React from "react";
import { Fade } from "react-reveal";
import { motion } from "framer-motion";
import { LineDecorator } from "../../Products/Woven/components/WovenMedias";
import { triangleDecoQA } from "./QAMedias";

const FirstQA = () => {
  const steps = [
    {
      title: "Rigorous Inspections",
      text: "Every stage of production undergoes thorough inspections to ensure adherence to quality standards.",
    },
    {
      title: "Pre-Production Checks",
      text: "Before manufacturing begins, materials and specifications are meticulously reviewed to prevent defects.",
    },
    {
      title: "In-Process Monitoring",
      text: "Ongoing monitoring during production to identify and address any issues promptly.",
    },
    {
      title: "Third-Party Testing",
      text: "Collaboration with reputable third-party testing agencies to verify the quality of fabrics, accessories, and finished garments.",
    },
    {
      title: "Final Inspection",
      text: "A comprehensive final inspection is conducted using the Acceptable Quality Level (AQL) method to ensure consistency and compliance before shipment.",
    },
  ];
  return (
    <>
      <div className="w-2/3 mx-auto text-black flex flex-col gap-6 my-10 relative">
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <div className="text-left flex flex-col gap-3">
            <p className="md:w-3/5 text-4xl">QUALITY ASSURANCE PROCESS</p>
            <p className="md:w-1/2">
              Our experienced quality assurance teams oversee every stage of
              product production through meticulously crafted quality systems.
              They not only perform inspections but also adhere to a three-tier
              inspection system from order placement to product delivery.
            </p>
          </div>
        </motion.div>

        <div className="flex flex-col gap-5">
          {steps.map((el, i) => {
            return (
              <Fade left={i % 2 === 0} right={i % 2 !== 0}>
                <div
                  key={i}
                  className={`flex flex-col gap-1 text-left w-80 ${
                    i % 2 === 0 ? "ml-auto" : "mr-auto"
                  }`}
                >
                  <p
                    className={`${
                      i % 2 === 0 ? "ml-auto" : "mr-auto"
                    } text-5xl text-slate-300`}
                  >
                    {i + 1}
                  </p>
                  <p className="font-semibold italic">{el.title}</p>
                  <p>{el.text}</p>
                </div>
              </Fade>
            );
          })}
        </div>

        <img
          src={triangleDecoQA}
          alt="icon"
          className="absolute top-[40%] -left-[20%] rotate-12 w-28"
        />

        <img
          src={triangleDecoQA}
          alt="icon"
          className="absolute top-[75%] -right-[15%] rotate-[75deg] w-28"
        />
      </div>
      <img src={LineDecorator} alt="icon" className="absolute top-[110%]" />
    </>
  );
};

export default FirstQA;
