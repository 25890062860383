export const cards = [
  {
    index: "01",
    title: "Unrivalled Quality",
    description:
      "Our unwavering commitment to quality sets us apart. Every garment crafted at Pole Star Group undergoes rigorous quality checks, ensuring that our clients receive products of unparalleled excellence.",
  },
  {
    index: "02",
    title: "Global Manufacturing Expertise",
    description:
      "With a global footprint and cutting-edge manufacturing facilities, we bring international standards to every stitch. Our expertise in catering to diverse markets positions us as your ideal partner for global success.",
  },
  {
    index: "03",
    title: "Swift Lead Times",
    description:
      "Time is of the essence, and we understand that. Our streamlined processes guarantee quick lead times, ensuring that your products reach the market promptly.",
  },
  {
    index: "04",
    title: "Efficiency in Every Order",
    description:
      "Benefit from our efficient manufacturing processes that guarantee consistency, even at scale. Our minimum order quantity (MOQ) ensures optimal efficiency, reducing costs for larger orders.",
  },
  {
    index: "05",
    title: "Sustainable Practices",
    description:
      "Embracing the principles of sustainability, our operations are guided by eco-friendly practices. Partnering with us means contributing to a greener future without compromising on quality.",
  },
  {
    index: "06",
    title: "End-to-End Enterprise Resource Planning (ERP)",
    description:
      "Our core functions are driven by state-of-the-art ERP systems, minimizing wastage, increasing efficiency, and ensuring a seamless production journey.",
  },
  {
    index: "07",
    title: "People-Centric Approach",
    description:
      "Our success is not just about garments; it's about people. We follow a strict Code of Conduct, fostering a workplace where every individual, including employees and clients, is valued.",
  },
  {
    index: "08",
    title: "Global Partnerships with International Brands",
    description:
      "Join a network of global partnerships as we collaborate with international brands. Pole Star Group is your gateway to establishing and expanding your presence in the global fashion landscape.",
  },
  {
    index: "09",
    title: "Electronic Payment Convenience",
    description:
      "Experience convenience with our electronic payment system, reducing costs, enhancing security, and streamlining financial transactions.",
  },
];
