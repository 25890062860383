import { Slide, Zoom } from "react-reveal";
import { SixthSectionCards } from "./OurTeamMedias";

const SixthSection = () => {
  return (
    <>
      <div className="w-11/12 mx-auto mt-10 flex flex-col justify-center items-center gap-5">
        <Zoom duration={1200}>
          <p className="text-[30px] lg:text-[48px] font-Playfair text-[#0F0E11] lg:px-20 pb-3">
            Synonymous with unparalleled quality, creativity, and value in the
            world of apparel manufacturing
          </p>
        </Zoom>
      </div>

      <div className="flex flex-row flex-wrap justify-center items-center gap-5">
        {SixthSectionCards.map((el, i) => {
          return (
            <Slide bottom duration={1400}>
              <div className="flex flex-col justify-start items-center gap-3 w-[300px] h-[400px]">
                <img src={el.img} alt={el.title} />
                <p className="text-[20px] font-Playfair text-[#0F0E11] font-semibold">
                  {el.title}
                </p>
                <p className="text-[20px] font-Poppins text-[#59575B]">
                  {el.description}
                </p>
              </div>
            </Slide>
          );
        })}
      </div>
    </>
  );
};

export default SixthSection;
