import React from "react";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Link } from "react-router-dom";

const MediaLinks = () => {
  return (
    <div className="hidden absolute top-1/2 -translate-y-1/2 right-7 md:flex flex-col gap-1">
      <Link to="/">
        <InstagramIcon className="text-[#d62976] hover:cursor-pointer hover:shadow rounded-md" />
      </Link>

      <div className="h-7 w-[1px] bg-[#ed2225] mx-auto" />

      <Link to="/">
        <FacebookIcon className="text-blue-800 hover:cursor-pointer hover:shadow rounded-md" />
      </Link>
      <div className="h-7 w-[1px] bg-[#17371b] mx-auto" />

      <Link to="/">
        <LinkedInIcon className="text-blue-600 hover:cursor-pointer hover:shadow rounded-md" />
      </Link>
    </div>
  );
};

export default MediaLinks;
