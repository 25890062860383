import React from "react";
import { SweaterFirstSectionContent } from "./SweaterMedias";

const SweaterFirstSection = () => {
  const title = "Monthly production capacity: 180000 pieces of knitwear.";
  const description =
    "The main products of the company are all kinds of knitwear and sweaters like Sweater, Pullover, Cardigan, Scarf, Caps, for Men/Women/Kids, in Basic & Jacquard Design & Fancy styles";

  return (
    <div className="flex flex-row flex-wrap gap-10 justify-center p-2">
      <div className="text-left w-full md:w-1/3 lg:w-1/4 text-black">
        <p className="font-semibold text-3xl font-Playfair">{title}</p>
        <p className=" font-light text-lg mt-5 font-Poppins">{description}</p>
      </div>
      <div className="w-full md:w-max grid grid-cols-2 gap-5">
        {SweaterFirstSectionContent.map((el, i) => {
          return (
            <div className="text-center size-[230px] flex flex-col">
              <div className="relative">
                <img
                  alt="decorator"
                  src={el.bgDecoratorSrc}
                  className="size-[100px]"
                />
                <img
                  alt="decorator"
                  className="size-[50px] absolute top-[25px] left-[25px]"
                  src={el.icon}
                />
              </div>
              <div className="text-left text-[#59575B] font-Poppins">
                <p className="text-2xl">{el.title}</p>
                <p className="text-lg">{el.text}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SweaterFirstSection;
