import { medialUrl } from "../../../EndPoints";
import { ApartmentIcon } from "../../Woven/components/WovenMedias";

export const KnitHeroImage = `${medialUrl}/img/Knit/Knit_hero.png`;

const LadiesLongSleeveImage = `${medialUrl}/img/Knit/Knit_1.png`;
const LadiesTankTopImage = `${medialUrl}/img/Knit/Knit_2.png`;
const LadiesTShirtImage = `${medialUrl}/img/Knit/Knit_3.png`;
const LadiesFrockImage = `${medialUrl}/img/Knit/Knit_4.png`;
const PoloTShirtImage = `${medialUrl}/img/Knit/Knit_5.png`;
const NightDressImage = `${medialUrl}/img/Knit/Knit_6.png`;
const KidsItemImage = `${medialUrl}/img/Knit/Knit_7.png`;
const YogaImage = `${medialUrl}/img/Knit/Knit_8.png`;
export const knitArray = [
  {
    img: LadiesLongSleeveImage,
    text: "Ladies Long Sleeve T-Shirt",
  },
  {
    img: LadiesTankTopImage,
    text: "Ladies Tank Top",
  },
  {
    img: LadiesTShirtImage,
    text: "Ladies S/S T-Shirt",
  },
  {
    img: LadiesFrockImage,
    text: "Ladies Frock",
  },
  {
    img: PoloTShirtImage,
    text: "Polo Shirt",
  },
  {
    img: NightDressImage,
    text: "Night Dress",
  },
  {
    img: KidsItemImage,
    text: "Kids’ Item",
  },
  {
    img: YogaImage,
    text: "Yoga",
  },
];

export const knitStatCounter = [
  {
    number: 30,
    text: "Lines",
    icon: ApartmentIcon,
  },
  {
    number: "0.0",
    sign: "%",
    text: "Re-work & Re-check",
    icon: ApartmentIcon,
  },
];
