import React from "react";
import { Slide, Zoom } from "react-reveal";
import { Link } from "react-router-dom";
import {
  PriceQualityShipmentLeftImage,
  PriceQualityShipmentMiddleImage,
  PriceQualityShipmentPonits,
  PriceQualityShipmentRightImage,
} from "./OurTeamMedias";

const PriceQualityShipment = () => {
  return (
    <div className="flex flex-col lg:flex-row justify-center items-center gap-5 w-11/12 mx-auto">
      <div className="w-full lg:w-1/2 flex flex-col justify-center items-center">
        <div className="flex flex-row mb-[-100px]">
          <Slide left duration={1400}>
            <img
              src={PriceQualityShipmentLeftImage}
              alt="Gaments Environment"
              className="w-40 md:w-52 h-auto"
            ></img>
          </Slide>
          <Slide right duration={1400}>
            <img
              src={PriceQualityShipmentRightImage}
              alt="Gaments Environment"
              className="z-20 w-40 md:w-52 h-auto"
            ></img>
          </Slide>
        </div>

        <Zoom duration={1200}>
          <img
            src={PriceQualityShipmentMiddleImage}
            alt="Gaments Environment"
            className="z-10 w-40 md:w-52 h-auto"
          ></img>
        </Zoom>
      </div>

      <Slide right duration={1400}>
        <div className="w-full lg:w-1/2 text-left flex flex-col gap-5">
          <p className="text-[48px] font-Playfair text-[#0F0E11]">
            Price.Quality.On-time Shipment
          </p>
          <p className="text-[20px] font-Poppins text-[#59575B]">
            Pole Star Group, situated in Dhaka, Bangladesh, South Asia, operates
            as an apparel-focused company with two distinct units: a
            Manufacturing Unit and a Sourcing Unit. The Fashion Group
            specializes in both woven and knit garments, supplying a diverse
            range of clothing products for Men, Ladies, Boys, Girls, and Babies.
          </p>

          {PriceQualityShipmentPonits.map((el, i) => {
            return (
              <div
                key={i}
                className="flex flex-row justify-start items-start text-[20px] font-Poppins font-semibold text-[#59575B]"
              >
                <img src={el.image} alt={el.alt} className="w-6" />
                <p className="pl-2">{el.text}</p>
              </div>
            );
          })}

          <Link
            to="quality-assurance"
            className="bg-[#ED2025] text-white border rounded-full px-7 py-2 w-max"
          >
            Quality Assurance
          </Link>
        </div>
      </Slide>
    </div>
  );
};

export default PriceQualityShipment;
