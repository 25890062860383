import React from "react";
import Header from "../components/Header";
import Achievements from "../Home/components/Achievements";
import FirstQA from "./components/FirstQA";
import { heroQAbg } from "./components/QAMedias";

const QA = () => {
  return (
    <>
      <Header
        src={heroQAbg}
        alt="About Us - Mission & Vision"
        title="Ensuring Excellence:"
        title2="Our Commitment to Quality Assurance in Every Stitch"
      />
      <FirstQA />
      <Achievements />
    </>
  );
};

export default QA;
