import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Counter from "../../components/Counter";
import MediaLinks from "../../components/MediaLinks";
import HeroLeftContent from "../../components/HeroLeftContent";
import { medialUrl } from "../../EndPoints";

const HomeFirst = () => {
  const [textIndex, setTextIndex] = useState(0);
  const texts = [
    "Price, Quality & On-time Shipment.",
    "Global Excellence, Local Expertise",
    "Inspired Designs, Impeccable Quality",
    "Your Gateway to Exceptional Garments",
  ];

  const textVariants = {
    hidden: { opacity: 0, y: "-100%" },
    visible: { opacity: 1, y: "0%" },
    exit: { opacity: 0, y: "100%" },
  };

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [
    `${medialUrl}/img/Home/polestar_close_hanging_clothing_rack_in_a_shop.jpg`,
    `${medialUrl}/img/Home/polestar_closeup_colorful_hanging_t_shirts.jpg`,
    `${medialUrl}/img/Home/polestar_colorful_women_dresses_in_retail.jpg`,
    `${medialUrl}/img/Home/polestar_close_hanging_clothing_rack_in_a_shop.jpg`,
  ];
  const backgroundStyle = {
    backgroundImage: `url(${images[currentImageIndex]})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    transition: "background-image 1s ease-in-out",
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 8000);

    return () => clearInterval(interval);
  }, [texts.length, images.length]);

  const handleNextClick = () => {
    setTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrevClick = () => {
    setTextIndex((prevIndex) => (prevIndex - 1 + texts.length) % texts.length);
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const stat = [
    // { number: 1200, sign: "+", text: "Employees", icon: PeopleIcon },
    {
      number: 95,
      sign: "%",
      text: "On-time Delivary",
      icon: `${medialUrl}/img/icons/Delivery.jpg`,
    },
    {
      number: "0.0",
      sign: "%",
      text: "Re-work & Re-check",
      icon: `${medialUrl}/img/icons/Apartment.jpg`,
    },
  ];

  return (
    <>
      <div>
        <div
          className="h-[580px] md:h-[450px] w-4/5 mt-16 md:mt-28 mx-auto text-black relative rounded-2xl flex flex-row flex-wrap"
          style={backgroundStyle}
        >
          <div className="absolute rounded-xl bg-black/70 p-4 top-[4%] md:top-[10%] lg:top-[17%] left-5 flex flex-col gap-5 text-white font-Lato w-[90%] md:w-1/2 text-left">
            <motion.div
              key={textIndex}
              initial="hidden"
              animate="visible"
              exit="exit"
              variants={textVariants}
              transition={{ duration: 1 }}
            >
              <p className="text-base md:text-xl flex flex-row flex-wrap gap-3 font-semibold">
                <div className="w-7 h-[2px] bg-white my-auto" />
                Where Quality Meets Innovation
              </p>
              <h1 className="font-Playfair text-4xl md:text-5xl font-semibold md:leading-normal animate-fadeInOut transition delay-150 duration-300 ease-in-out">
                {texts[textIndex]}
              </h1>
            </motion.div>

            <div className="flex gap-3 bg-white/70 w-max rounded-xl p-3">
              <button
                className="btn btn-sm btn-circle btn-outline"
                onClick={handlePrevClick}
              >
                <ArrowBackIcon fontSize="1px" />
              </button>
              <button
                className="btn btn-sm btn-circle btn-outline"
                onClick={handleNextClick}
              >
                <ArrowForwardIcon fontSize="1px" />
              </button>
            </div>
          </div>

          <HeroLeftContent />
        </div>

        <Counter stat={stat} />
      </div>
      <MediaLinks />
    </>
  );
};

export default HomeFirst;
