import React from "react";
import FirstMissionVision from "./components/FirstMissionVision";
import AboutHero from "../components/AboutHero";
import ThirdMissionVision from "./components/ThirdMissionVision";
import SecondMissionVision from "./components/SecondMissionVision";
import { heroOurTeamImage } from "../OurTeam/components/OurTeamMedias";

const MissionVision = () => {
  return (
    <div className="flex flex-col gap-12">
      <AboutHero
        image={heroOurTeamImage}
        alt={"About - Mission & Vision"}
        title={"About"}
      >
        <p className="">
          About / Our Team / <strong>Mission & Vision</strong>
        </p>
      </AboutHero>
      <FirstMissionVision />
      <SecondMissionVision />
      <ThirdMissionVision />
    </div>
  );
};

export default MissionVision;
