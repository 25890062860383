import { medialUrl } from "../../../EndPoints";

export const heroOurTeamImage = `${medialUrl}/images/about-bg.png`;
export const organogramImage = `${medialUrl}/img/About/OurTeam/Organoram.png`;

// MessageMD
export const MDImage = `${medialUrl}/images/Md.png`;

// PriceQualityShipment
export const PriceQualityShipmentLeftImage = `${medialUrl}/images/left.png`;
export const PriceQualityShipmentMiddleImage = `${medialUrl}/images/middle.png`;
export const PriceQualityShipmentRightImage = `${medialUrl}/images/right.png`;
export const PeopleIcon = `${medialUrl}/images/people-icon.png`;
export const OkIcon = `${medialUrl}/images/ok-icon.png`;
export const PriceQualityShipmentPonits = [
  {
    image: PeopleIcon,
    alt: "People",
    text: "Minimum order quantity is 2,000 pieces per color, per style",
  },
  {
    image: OkIcon,
    alt: "Ok",
    text: "Transactions are guaranteed safe",
  },
];

// SixthSection - OurTeam
const ProcessQualityImage = `${medialUrl}/images/sixth1.png`;
const DesignDevelopmentImage = `${medialUrl}/images/sixth2.png`;
const LeadTimeImage = `${medialUrl}/images/sixth3.png`;
const ERPImage = `${medialUrl}/images/sixth4.png`;

export const SixthSectionCards = [
  {
    img: ProcessQualityImage,
    title: "Process & Quality",
    description:
      "A strict regime of internal audits, critical process check, 3rd party testing on fabric / accessories / garments and Pre-final Inspection @ AQL 2.5 are done prior to shipment.",
  },
  {
    img: DesignDevelopmentImage,
    title: "Design & Development",
    description:
      "We can assist customer creating fits and design for garments. We offer choices of fabrication, wide range of accessories and different packaging method: flat pack, boxed pack, hanging garments, etc.",
  },
  {
    img: LeadTimeImage,
    title: "Lead Time",
    description:
      "90 days from fabric approval (imported fabric by sea shipment)- 120 days from fabric / print design or color selection (imported fabric by sea shipment).",
  },
  {
    img: ERPImage,
    title: "ERP (Enterprise Resource Planning)",
    description:
      "Our core functions are based on Enterprise Resource Planning (ERP). We strive continuously to reduce wastage of materials and increase production efficiency.",
  },
];
